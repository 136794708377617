import React, { useState, useRef, useEffect } from "react";
import "./modal.css";
import Modal from "./modal";
import { axiosApiInstance, BASE_URL, failureAlert, successAlert } from "../../helpers/helper";
import { uploadFile } from "../../redux/actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { getMyPlans, getOrganisation } from "../../redux/actions/plansApiActions";
import { getOrganisationUsers } from "../../redux/actions/organisationActions";
const MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024;
const CustomFieldModal = ({ modalid, toggle, setOpenAddFieldModal }) => {
  const [fields, setFields] = useState(["Age", "Business Unit", "Location", "Sex"]);
  const [customFields, setCustomFields] = useState([]);
  const [checkedDefault, setCheckedDefault] = useState({});
  const [checkedCustom, setCheckedCustom] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const UserInfo = useSelector((state) => state.getUser)
  const { userInfo } = UserInfo
  const GetOrganisation = useSelector((state) => state.getOrganisation);
  const { orgDetailsByEmail } = GetOrganisation;

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo && !orgDetailsByEmail) {
      dispatch(getOrganisation(userInfo.data.email));
    }
  }, [userInfo]);

  const handleAddField = () => {
    setCustomFields([...customFields, ""]);
  };

  const handleInputChange = (index, value) => {
    const updatedFields = [...customFields];
    updatedFields[index] = value;
    setCustomFields(updatedFields);
    validateDuplicates();
  };

  const handleDeleteField = (index) => {
    const updatedCustomFields = customFields.filter((_, i) => i !== index);
    setCustomFields(updatedCustomFields);
    const updatedCheckedCustom = { ...checkedCustom };
    delete updatedCheckedCustom[index];
    setCheckedCustom(updatedCheckedCustom);
    validateDuplicates(updatedCheckedCustom, checkedDefault);
  };


  const validateDuplicates = (custom = checkedCustom, defaults = checkedDefault) => {
    const selectedDefaultFields = fields
      .filter((_, index) => defaults[index])
      .map((field) => field.trim().toLowerCase());

    const selectedCustomFields = customFields
      .filter((item, index) => custom[index] && item.trim())
      .map((field) => field.trim().toLowerCase());

    const checkedFields = [...selectedDefaultFields, ...selectedCustomFields];
    const seen = new Set();

    for (let field of checkedFields) {
      if (seen.has(field)) {
        setErrorMessage("Duplicate field names are not allowed");
        return;
      }
      seen.add(field);
    }

    setErrorMessage("");
  };

  const handleCheckChange = (type, index, isChecked) => {
    if (type === "default") {
      const updatedCheckedDefault = { ...checkedDefault, [index]: isChecked };
      setCheckedDefault(updatedCheckedDefault);
      validateDuplicates(checkedCustom, updatedCheckedDefault);
    } else {
      const updatedCheckedCustom = { ...checkedCustom, [index]: isChecked };
      setCheckedCustom(updatedCheckedCustom);
      validateDuplicates(updatedCheckedCustom, checkedDefault);
    }
  };

  const handleSubmit = async () => {

    if (errorMessage) return;
    let addedFields = false
    const selectedDefaultFields = fields.filter((_, index) => checkedDefault[index]);
    const selectedCustomFields = customFields.filter((item, index) => checkedCustom[index] && item.trim());
    if (orgDetailsByEmail && orgDetailsByEmail?.data?.orgUserFields?.length <= 0 && [...selectedDefaultFields, ...selectedCustomFields].length <= 0) {
      return failureAlert("Please add custom fields")
    }
    if ([...selectedDefaultFields, ...selectedCustomFields].length > 0) {
      const addCustomFields = await axiosApiInstance.post(BASE_URL + "/api/user/public/add-org-fields", { fields: [...selectedDefaultFields, ...selectedCustomFields] })
      if (addCustomFields && addCustomFields?.data?.message === "added fields sucessfully") {
        if (!selectedFile) {
          successAlert("successfully added new fields")
          if (userInfo) {
            dispatch(getMyPlans());
            dispatch(getOrganisation(userInfo.data.email));
          }
          setOpenAddFieldModal(false)
        }
        addedFields = true
      }
      else {
        failureAlert("failed to add custom fields")
      }

    }

    if (selectedFile && [...selectedDefaultFields, ...selectedCustomFields].length <= 0 && !addedFields || [...selectedDefaultFields, ...selectedCustomFields].length >= 0 && addedFields && selectedFile) {
      const fileData = new FormData();
      fileData.append("sheets", selectedFile);
      const { status, data } = await dispatch(uploadFile(fileData, true));
      if (status === 200) {
        if (data && data.data && data?.data?.path) {
          const { path } = data.data;
          const response = await axiosApiInstance.post(BASE_URL + "/api/user/public/add-user-fields-data", { filePath: path })
          if (response && response?.data && response?.data?.message === "Batch update complete") {
            if (response?.data?.results?.length > 0) {
              const sucessFalse = response?.data?.results.filter(item =>
                (item.success === false && item.message.includes("Invalid fields present"))
              );
              if (sucessFalse.length > 0) {
                const messageContent = sucessFalse.map(item => item.message).join("\n");
                failureAlert(`updated users info sucessfully\n ${messageContent}`)

                if (userInfo) {
                  dispatch(getMyPlans());
                  dispatch(getOrganisationUsers('', false, 1));
                  dispatch(getOrganisation(userInfo.data.email));
                }
              }
              else {
                if (response?.data?.blank_entries) {
                  failureAlert(`successfully updated users with new fields value also ` + response?.data?.blank_entries)

                  if (userInfo) {
                    dispatch(getMyPlans());
                    dispatch(getOrganisationUsers('', false, 1));
                    dispatch(getOrganisation(userInfo.data.email));
                  }
                }
                else {
                  successAlert("successfully updated all users with new fields value")
                  if (userInfo) {
                    dispatch(getMyPlans());
                    dispatch(getOrganisationUsers('', false, 1));
                    dispatch(getOrganisation(userInfo.data.email));
                  }
                  setOpenAddFieldModal(false)

                }

              }

            }
            else {
              if (response?.data?.results?.length === 0 && response?.data?.blank_entries) {
                failureAlert(response?.data?.blank_entries)
              }
              failureAlert("failed to updated users fields data")
            }


          }
        }
        else {
          failureAlert("Opps something went wrong");
        }

      }
      else {
        failureAlert("Opps something went wrong");
      }
    }
    else {
      failureAlert("Please  upload CSV");
    }

  };


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const [error, setError] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {

      if (file.type !== "text/csv") {
        setError("Only CSV files are allowed.");
        setSelectedFile(null);
        return;
      }


      if (file.size > MAX_FILE_SIZE) {
        setError("File size exceeds the 1GB limit.");
        setSelectedFile(null);
        return;
      }


      setSelectedFile(file);
      setError("");
    }
  };
  return (
    <Modal modalid={modalid} toggle={toggle}>
      <div className="modal-body">
        <div className="close-icon" onClick={() => setOpenAddFieldModal(false)}>
          <div className="close-btn-icon"></div>
        </div>
        <div className="thankyou-body add-fields customCnter">
          <h3 className="!m-0 custom-field-header !mb-3">Add Fields</h3>
          <input
            type="file"
            ref={fileInputRef}
            accept=".csv"
            className="hidden"
            onChange={handleFileChange}
          />
          <ul className="space-y-2 max-h-[250px] overflow-y-auto">
            {fields.map((item, index) => (
              <li key={`default-${index}`} className="flex justify-between items-center pb-2">
                <div className="font-bold text-[#23282e] text-[16px]">{item}</div>
                <input
                  type="checkbox"
                  className="form-checkbox w-[18px] h-[18px]"
                  checked={checkedDefault[index] || false}
                  onChange={(e) => handleCheckChange("default", index, e.target.checked)}
                />
              </li>
            ))}


            {customFields.map((item, index) => (
              <li key={`custom-${index}`} className="flex justify-between items-center pb-3">
                <input
                  type="text"
                  value={item}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className="bg-gray-100 form-control p-1 pl-3 rounded-md w-[70%] text-[16px]"
                  placeholder="Enter field name"
                />
                <div className="flex items-center">
                  <button
                    onClick={() => handleDeleteField(index)}
                    className="mr-5 text-red-500 hover:text-red-700"
                  >
                    ✖
                  </button>
                  <input
                    type="checkbox"
                    className="form-checkbox w-[18px] h-[18px]"
                    checked={checkedCustom[index] || false}
                    disabled={!item.trim()}
                    onChange={(e) => handleCheckChange("custom", index, e.target.checked)}
                  />
                </div>
              </li>
            ))}
          </ul>


          {errorMessage && <p className="mt-2 error_msg">{errorMessage}</p>}


          <div className="flex justify-center mt-3">
            <button className="flex items-center !px-4 !py-1 btn customAddFieldBtn" onClick={handleAddField}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 w-3 h-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Add Field
            </button>
          </div>

          <div className="custom-field-bg mt-6 p-6 w-full text-white">
            <div className="flex sm:flex-row flex-col justify-between items-start sm:items-center gap-4">
              <div className="max-w-md">
                <h3 className="font-bold text-[#23282e] text-lg">Upload File</h3>
                <p className="opacity-50 mt-1 text-[#23282e] text-sm">
                  Upload CSV file to add users
                </p>
              </div>


              <input
                type="file"
                ref={fileInputRef}
                accept=".csv"
                className="hidden"
                onChange={handleFileChange}
              />


              <button
                className="flex items-center !px-4 !py-1 btn customAddFieldBtn"
                onClick={handleButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 w-4 h-4"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 18v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2"></path>
                  <polyline points="16 6 12 2 8 6"></polyline>
                  <line x1="12" y1="2" x2="12" y2="15"></line>
                </svg>
                Upload CSV
              </button>
            </div>


            {selectedFile && (
              <div className="mt-2 w-full text-left">
                <p className="font-bold text-[#23282e] text-sm">File Selected: {selectedFile.name}</p>
              </div>
            )}


            {error && (
              <p className="error_msg">{error}</p>
            )}
          </div>

          <div className="flex justify-center mt-6">
            <button
              className="!px-6 !py-2 btn customAddFieldSubmitBtn"
              onClick={handleSubmit}
              disabled={errorMessage !== ""}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomFieldModal;
