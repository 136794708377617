import React, { useRef } from 'react';
import './modal.css';
import Modal from './modal';

import { MdErrorOutline } from "react-icons/md";
import { BsUpload } from 'react-icons/bs';

import avatar1 from "../../assets/myDetail/avatars/1.png"
import avatar2 from "../../assets/myDetail/avatars/2.png"
import avatar3 from "../../assets/myDetail/avatars/03@2x.png"
import avatar4 from "../../assets/myDetail/avatars/04@2x.png"
import avatar5 from "../../assets/myDetail/avatars/05@2x.png"
import avatar6 from "../../assets/myDetail/avatars/06@2x.png"
import avatar7 from "../../assets/myDetail/avatars/07@2x.png"
import avatar8 from "../../assets/myDetail/avatars/08@2x.png"
import avatar9 from "../../assets/myDetail/avatars/09@2x.png"
import avatar10 from "../../assets/myDetail/avatars/10@2x.png"
import avatar11 from "../../assets/myDetail/avatars/11@2x.png"
import avatar12 from "../../assets/myDetail/avatars/12@2x.png"
import avatar13 from "../../assets/myDetail/avatars/13@2x.png"
import avatar14 from "../../assets/myDetail/avatars/14@2x.png"
import avatar15 from "../../assets/myDetail/avatars/15@2x.png"
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserPointsInfo, update } from '../../redux/actions/userAction';
import { axiosApiInstance, BASE_URL, failureAlert, successAlert } from '../../helpers/helper';


const UploadProfileAvatarModel = ({ toggle, setIsOpen, setsuccessAlert, setfailureAlert }) => {

  const AvatarsArray = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14, avatar15]

    const UserInfo = useSelector(state => state.getUser)
    const { userInfo } = UserInfo;

     const uploadProfileRef = useRef(null)

     const dispatch = useDispatch()
  
      const handleButtonClick = () => {
        uploadProfileRef.current.click();
      };

      const fileChanged = (e) => {
        e.persist();
        const file = e.target.files[0];

        e.target.value = null;
      
        if (!file) return;
      
        if (file.size > 1024 * 1024) {
          console.error("Error: Image size should not exceed 1MB.");
          failureAlert("Error: Image size should not exceed 1MB.")
          return;
        }
      
        const img = new Image();
        img.onload = async function () {
          const height = this.height;
          const width = this.width;
      
          const data = new FormData();
          data.append("profile-uploads", file);
      
          axiosApiInstance.post(BASE_URL + "/api/file/upload", data)
            .then(async (response) => {
              const responseCode = await dispatch(update(userInfo?.data?.email, { profileImg: response.data.data.location }));
              if (responseCode === 200) {
                successAlert("Profile image updated");
                dispatch(getUser());
                dispatch(getUserPointsInfo());
                setIsOpen(false);
              }
            })
            .catch((err) => {
              console.error(`Fetch Error: ${err}`);
            });
        };
      
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(file);
      };
      
      const uploadAvatar = async(e, avatarSrc)=>{
        e.preventDefault()
        try {
          const response = await fetch(avatarSrc);
          const blob = await response.blob();
          
          const data = new FormData();
          data.append("profile-uploads", blob, "avatar.png");
    
          const uploadResponse = await axiosApiInstance.post(BASE_URL + "/api/file/upload", data);
    
          const responseCode = await dispatch(update(userInfo?.data?.email, { profileImg: uploadResponse.data.data.location }));
          if (responseCode === 200) {
            setsuccessAlert("Profile avatar updated");
            dispatch(getUser());
            dispatch(getUserPointsInfo());
            setIsOpen(false);
          }
        } catch (error) {
          console.error("Error uploading avatar:", error);
          setfailureAlert("Failed to upload avatar");
        }
      }

  return (
    <Modal modalid={"avatar-profile-upload"} toggle={toggle}
    >
      <div className="modal-body">
        <div className="close-icon" data-dismiss="modal" aria-label="Close">
          <div className="close-btn-icon"
            onClick={(e) => {
              setIsOpen(false)
            }}
          ></div>
        </div>
        <div 
        // className='w-full sm:w-full md:w-[790px] lg:w-[950px] thankyou-body'
        className="flex flex-col items-start bg-white px-[15px] md:px-[40px] lg:px-[50px] py-[20px] md:py-[48px] lg:py-[38px] rounded-[6px] w-full md:w-[790px] lg:w-[950px]"
        >
          <h1 className='font-sans font-semibold text-[19px] md:text-[22px] lg:text-[25px]'>Upload Profile <span className='font-sans font-semibold text-[19px] text-themeColor md:text-[22px] lg:text-[25px]'>Picture</span></h1>

          <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col justify-between items-start md:items-center lg:items-center bg-[#F6F9FE] mt-[15px] px-[15px] md:px-[30px] lg:px-[30px] py-[15px] rounded-[6px] w-full h-auto md:h-[92px] lg:h-[92px]">
            <div className="flex flex-col justify-between items-start h-full">
              <h3 className='font-sans font-semibold text-[14px] md:text-[16px] lg:text-[18px]'>Upload an image as your team logo</h3>
              <div className='flex flex-row mt-[8px] sm:mt-0 md:mt-0 lg:mt-0'>
                <MdErrorOutline
                  className='mr-[5px] w-[19px] h-[19px] text-[#D83307]'
                />
                <span className='font-sans text-[#D83307] text-[10px] md:text-[14px] lg:text-[14px]'>The file size should not exceed 1MB. (JPg, PNG)</span>
              </div>
            </div>
            <button type="submit"
              className={` btn btn-secondry mt-[15px] sm:mt-0 md:mt-0 lg:mt-0 w-[100%] sm:w-[100px] md:w-[120px] lg:w-[120px] h-[30px] sm:h-[32px] md:h-[32px] lg:h-[32px] hover:!text-white`}
              style={{
                padding: '0px'
              }}
            onClick={handleButtonClick}
            >
              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                onChange={fileChanged}
                className="hidden"
              ref={uploadProfileRef}
              />
              <BsUpload />
              <span className="text-[10px] md:text-[14px] lg:text-[14px] hover:!text-white"> Upload File</span>
            </button>
          </div>

          <span className='my-[20px] md:my-[30px] lg:my-[30px] w-full font-sans font-semibold text-[18px] text-center md:text-[22px] lg:text-[25px]'>OR</span>

          <h1 className='font-sans font-semibold text-[19px] md:text-[22px] lg:text-[25px]'>Select Your <span className='font-sans font-semibold text-[19px] text-themeColor md:text-[22px] lg:text-[25px]'>Avatar</span></h1>

          <div className="gap-3 md:gap-5 lg:gap-5 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 mt-[10px] w-full max-w-full h-[230px] sm:h-[260px] md:h-[300px] lg:h-[330px] overflow-y-scroll">
            {AvatarsArray.map((avatarImag, index) => (
              <div
                key={index}
                onClick={(e)=>uploadAvatar(e, avatarImag)}
                className="flex justify-center items-center hover:border-[2px] hover:border-[#0060FF] rounded-full w-[85px] sm:w-[90px] md:w-[110px] lg:w-[130px] h-[85px] sm:h-[90px] md:h-[110px] lg:h-[130px] transition-all cursor-pointer overflow-hidden"
              >
                <img src={avatarImag} alt="avatar-image" 
                // className="w-[75px] sm:w-[80px] md:w-[100px] lg:w-[120px] h-[75px] sm:h-[80px] md:h-[100px] lg:h-[120px] object-fill" 
                className="w-full h-full object-fill"
                />
              </div>
            ))}
          </div>

        </div>
      </div>
    </Modal>
  );
};
export default UploadProfileAvatarModel;
