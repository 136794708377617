import React, { useState } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { logOut } from "../../redux/actions/userAction";
import "./index.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
// import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { useEffect } from "react";
import { getAllPaymentForAdmin } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { failureAlert, IsAdmin, successAlert } from "../../helpers/helper";
import Select from "react-select";
import { SYMBOLS } from "../../constants";
import { getAllOfflineQuotes } from "../../redux/actions/offlineQuoteApiActions";
import { getAllQuartersInfo, addQuartersImages, updateQuarterData, addOccasionGamesQuartersData, getAllCalendarData, updateOccasionGamesInQuarter, updateQuarterColors } from "../../redux/actions/calendarActions";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from '../../redux/actions/commonActions';
import {
  S3_BASE_URL,
  BASE_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData
} from "../../helpers/helper";
import { cos } from "synonyms/dictionary";
import { getAllGames } from "../../redux/actions/homepageActions";
import moment from "moment";
const CalendarAdmin = (props) => {
  const [loaded, setLoaded] = useState(true);
  const AllGames = useSelector(state => state.allGames);
  const [selectedValue, setSelectedValue] = useState("Employee Engagement");
  const { allGames } = AllGames;
  const [allQuartersInfo, setAllQuartersInfo] = useState([]);
  const [addedCalendarData, setAllCalendarData] = useState(null);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  const allQuarterInfoData = useSelector((state) => state.allQuartersInfo);
  const calendarData = useSelector((state) => state.calendarData);
  const defaultRows = [
    { quarter: '1', image: '', file: null, path: '' },
    { quarter: '2', image: '', file: null, path: '' },
    { quarter: '3', image: '', file: null, path: '' },
    { quarter: '4', image: '', file: null, path: '' }
  ];
  const submitQuarterImages = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoaded(false);

      const newRows = rows.map((row) => ({
        ...row,
        color: row.color || "#000000",
      }));

      const newErrors = [];

      for (let i = 0; i < newRows.length; i++) {
        const row = newRows[i];
        const newRow = { ...row };

        if (row.file && row.mobilefile) {
          try {
            const data = new FormData();
            const data1 = new FormData();
            data.append("quarter-thumbnail", row.file);
            data1.append("quarter-thumbnail", row.mobilefile);

            const [desktopResponse, mobileResponse] = await Promise.all([
              dispatch(uploadFile(data)),
              dispatch(uploadFile(data1)),
            ]);

            const desktopPath = desktopResponse?.data?.data?.path;
            const mobilePath = mobileResponse?.data?.data?.path;

            if (desktopPath && mobilePath) {
              newRow.path = desktopPath;
              newRow.mobilePath = mobilePath;
              newRow.type = selectedValue;
            } else {
              newErrors[i] = "File upload failed for desktop or mobile thumbnail.";
            }
          } catch (error) {
            newErrors[i] = "File upload failed due to a server or network error.";
          }
        } else {
          if (!row.file) newErrors[i] = "Desktop thumbnail is required.";
          if (!row.mobilefile) newErrors[i] = "Mobile thumbnail is required.";
        }

        newRows[i] = newRow;
      }

      const response = await dispatch(addQuartersImages({ allQuartersData: newRows }));

      if (response === 200) {
        successAlert("Data Added Successfully!");
        dispatch(getAllQuartersInfo(selectedValue));
        setLoaded(true);
      } else {
        failureAlert("Something went wrong!");
      }
      setLoaded(true);
    
      setRows(defaultRows);
    } else {
      console.log("There are errors in the form");
      setLoaded(true);
    }
  };

  const [errors, setErrors] = useState([]);

  const validateForm = () => {
    const newErrors = [];

    rows.forEach((row, index) => {
      if (!row.file) {
        newErrors[index] = newErrors[index] || {};
        newErrors[index].file = "Please upload desktop file";
      }
      if (!row.mobilefile) {
        newErrors[index] = newErrors[index] || {};
        newErrors[index].mobilefile = "Please upload mobile file";
      }
    });

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const [colors, setColors] = useState([])

  useEffect(() => {
    if (allQuarterInfoData) {

      const sortedArray = allQuarterInfoData?.allQuartersInfo?.data.sort((a, b) => a.name - b.name);

      setAllQuartersInfo(sortedArray);

      const colors = allQuarterInfoData?.allQuartersInfo?.data.map((row) => row.color);

      setColors(colors);

      if (allQuarterInfoData.loading) {

        setLoaded(false);

      } else {

        setLoaded(true);

      }
    }
  }, [allQuarterInfoData]);

  useEffect(() => {
    if (calendarData) {
      setAllCalendarData(calendarData?.allCalendarData?.data);
      setModifiedData(calendarData?.allCalendarData?.data)
      if (calendarData.loading) {
        setLoaded(false);
      } else {
        setLoaded(true);
      }
    }
  }, [calendarData]);
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [quarterString, setQuarterSrting] = useState('');
  const currentYear = new Date().getFullYear();

  const quarterMonths = {
    q1: ['January', 'February', 'March'],
    q2: ['April', 'May', 'June'],
    q3: ['July', 'August', 'September'],
    q4: ['October', 'November', 'December'],
  };

  const [months, setMonths] = useState([]);

  const handleQuarterChange = (event) => {
    setSelectedMonth("");
    setSelectedQuarter(event.target.value);

    const selectedQuarter = allQuartersInfo.find(quarter => quarter.id === event.target.value);
    const selectedQuarterName = selectedQuarter ? selectedQuarter.name : null;
    const Quarter = "q" + selectedQuarterName;
    setQuarterSrting(Quarter);

    setMonths(quarterMonths[Quarter] || []);
  };


  const [rows, setRows] = useState(defaultRows);
  const [activeTab, setActiveTab] = useState(1);
  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };


  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const newRows = [...rows];
      newRows[index].image = file;
      newRows[index].file = file;
      setRows(newRows);
    }
  };

  const handleColorChange = (index, e) => {
    const color = e.target.value;
    const newRows = [...rows];
    newRows[index].color = color;
    setRows(newRows);
  };
  const handleMobileFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const newRows = [...rows];
      newRows[index].mobileimage = file;
      newRows[index].mobilefile = file;
      setRows(newRows);
    }
  };

  const handleFileChangeAfterUpload = async (row, e, type) => {
    const file = e.target.files[0];
    if (file) {

    
      setLoaded(false);
      // const quarterImage = [row.path];
      const quarterImage = type === 'mobile' ? [row.mobilePath] : [row.path];
      await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, quarterImage);
      const data = new FormData();
      data.append("quarter-thumbnail", file);
      const response = await dispatch(uploadFile(data));
      const fileUploadedPath = response?.data?.data?.path;
      const responsecode = await dispatch(updateQuarterData(fileUploadedPath, row.id, type));
      dispatch(getAllQuartersInfo(selectedValue));
      setLoaded(true);
    }
  };


  useEffect(() => {

    setRows(defaultRows);
    setAllQuartersInfo([]);
    dispatch(getAllQuartersInfo(selectedValue));
    dispatch(getAllGames());
    
  }, [selectedValue])
  const [gameOptions, setGameOptions] = useState([]);
  const [gameOptions1, setGameOptions1] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [occasionName, setOccasionName] = useState("");
  const [occasionDate, setOccasionDate] = useState("");
  const [toDate, setToOccasionDate] = useState("");
  const [selectedGames, setSelectedGames] = useState([]);
  const [mobileThumbnail, setMobileThumbnail] = useState(null);
  const [desktopThumbnail, setDesktopThumbnail] = useState(null);
  const [mobileThumbnailPreview, setMobileThumbnailPreview] = useState(null);
  const [desktopThumbnailPreview, setDesktopThumbnailPreview] = useState(null);
  useEffect(() => {
    if (allGames && allGames.data) {
   
      const options = allGames.data.map(game => (
        { id: game.id, label: game.title, value: game.title }
      ))
      setGameOptions(options);
    }
  }, [allGames]);

  useEffect(() => {
    if (allGames && allGames.data) {
      const options = allGames.data.map(game => (
        { value: game.id, label: game.title }
      ))
      setGameOptions1(options);
    }
  }, [allGames]);
  useEffect(() => {
    if (activeTab === 3) {
      dispatch(getAllCalendarData(selectedValue));
    }
  }, [activeTab, selectedValue])

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };




  const handleGameSelect = (selectedOptions) => {
    setSelectedGames(selectedOptions);
  };
  const [errorsOcasionForm, setErrorsOcasionForm] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorsOcasionForm({});
    let validationErrors = {};
    if (!selectedQuarter) validationErrors.quarter = "Quarter is required.";
    if (!selectedMonth) validationErrors.month = "Month is required.";
    if (!occasionName) validationErrors.occasionName = "Occasion name is required.";
    if (!occasionDate) validationErrors.occasionDate = "From date is required.";
    if (!toDate) validationErrors.toDate = "To date is required.";
    if (!mobileThumbnail) validationErrors.mobileThumbnail = "Mobile thumbnail is required.";
    if (!desktopThumbnail) validationErrors.desktopThumbnail = "Desktop thumbnail is required.";
    if (occasionDate && toDate && moment(toDate).isBefore(moment(occasionDate))) {
      validationErrors.toDate = "To date must be greater than or equal to From date.";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrorsOcasionForm(validationErrors);
      return;
    }

    setLoaded(false);

    const desktop = new FormData();
    const mobile = new FormData();
    desktop.append("quarter-thumbnail", desktopThumbnail);
    mobile.append("quarter-thumbnail", mobileThumbnail);
    const desktopPath = await dispatch(uploadFile(desktop));
    const mobilePath = await dispatch(uploadFile(mobile));
    const result1 = desktopPath?.data?.data?.path;
    const result2 = mobilePath?.data?.data?.path;

    const occasionData = {
      occasionName: occasionName,
      month: selectedMonth,
      occasionDate: occasionDate,
      toDate: toDate,
      quarterId: selectedQuarter,
      games: selectedGames,
      desktopBanner: result1,
      mobileBanner: result2,
      type: selectedValue
    };


    try {
      const result = await dispatch(addOccasionGamesQuartersData(occasionData));

      if (result == 200) {
        successAlert('Data Added Succesfully');
        setSelectedQuarter('');
        setSelectedMonth('');
        setOccasionName('');
        setOccasionDate('');
        setToOccasionDate('');
        setSelectedGames([]);
        setErrors({});
        setDesktopThumbnailPreview(null);
        setMobileThumbnailPreview(null);
        setDesktopThumbnail(null);
        setMobileThumbnail(null)
        document.getElementById("mobile-thumbnail").value = "";
        document.getElementById("desktop-thumbnail").value = "";
        setLoaded(true);
      } else {
        failureAlert('Something went wrong');
        setLoaded(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      failureAlert('Something went wrong');
      setLoaded(true);
    }
  };

  const [modifiedData, setModifiedData] = useState('');
  const [openTitle, setOpenTitle] = useState('');

  const handleInputChange_1 = (e, quarterIndex, monthIndex, occasionIndex, field) => {
    const updatedData = [...modifiedData];
    updatedData[quarterIndex].months[monthIndex].occasions[occasionIndex][field] = e.target.value;
    setModifiedData(updatedData);
  };
  const handleFileChange1 = async (e, quarterIndex, monthIndex, occasionIndex, existingThumbnail, field) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      if (existingThumbnail) {
        setLoaded(false);
        const thumbnail = [existingThumbnail];
        await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, thumbnail);
      }
      const data = new FormData();
      data.append("quarter-thumbnail", file);
      const response = await dispatch(uploadFile(data));
      const fileUploadedPath = response?.data?.data?.path;

      if (!fileUploadedPath) throw new Error('File upload failed');

      const updatedData = [...modifiedData];
      updatedData[quarterIndex].months[monthIndex].occasions[occasionIndex][field] = fileUploadedPath;
      setModifiedData(updatedData);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.error("Error handling file change:", error);
    }
  };

  const handleGameSelect_1 = (selectedGames, quarterIndex, monthIndex, occasionIndex) => {
    const updatedData = [...modifiedData];

    updatedData[quarterIndex].months[monthIndex].occasions[occasionIndex].games = selectedGames.map(game => ({
      id: game.value,
      title: game.label
    }));

    setModifiedData(updatedData);
  };




  const handleUpdate = async (quarterIndex, monthIndex, occasionIndex) => {
    setLoaded(false);
    const updatedData = [...modifiedData];
    const occasionData = modifiedData[quarterIndex].months[monthIndex].occasions[occasionIndex];

if (new Date(occasionData.toDate) < new Date(occasionData.date)) {
  failureAlert("The To Date must be greater than or equal to From date");
  setLoaded(true);
  return;
}
    await dispatch(updateOccasionGamesInQuarter(occasionData));
    await dispatch(getAllCalendarData(selectedValue));
    successAlert('Data Updated Successfully');
    setLoaded(true);
  };

  const deleteOccasion = async (occasionId) => {
    setLoaded(false);
    await axiosApiInstance.delete(`${BASE_URL}/api/Calendar/delete/${occasionId}`);
    await dispatch(getAllCalendarData(selectedValue));
    successAlert('Deleted Successfully');
    setLoaded(true);

  };

  const handleMobileThumbnailChange = (e) => {
    const file = e.target.files[0];
    setMobileThumbnail(file);
    if (file) {
      setMobileThumbnailPreview(URL.createObjectURL(file));
    }
  };

  const handleDesktopThumbnailChange = (e) => {
    const file = e.target.files[0];
    setDesktopThumbnail(file);
    if (file) {
      setDesktopThumbnailPreview(URL.createObjectURL(file));
    }
  };
  const [openIndex, setOpenIndex] = useState(null);


  const handleAccordionToggle = (index, title) => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    setOpenTitle(title);
  };


  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
   
  };

  const handleColorChangeOnupdate = (index, newColor) => {
    const updatedColors = [...colors];
    updatedColors[index] = newColor;
    setColors(updatedColors);
  };

  const handleUpdateButtonClick = async () => {
    const updates = allQuartersInfo.map((row, index) => {
      if (colors[index] !== row.color) {
        return {
          id: row.id,
          color: colors[index],

        };
      }
      return null;
    }).filter(update => update !== null);




    if (updates.length > 0) {
      setLoaded(false);
      const res = await dispatch(updateQuarterColors(updates));

      if (res == 200) {

        successAlert("Data Updated Successfully");

        setLoaded(true);

      }
      else {

        failureAlert("Something went wrong");

        setLoaded(true);

      }
    }
  };
  return (
    <div className="admin-homepage">
      <PageLayout
        sidebartitle=""
        active={"Calendar"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        {...props}
        isAdmin={isAdmin}
      >
        <LoadingComponent loaded={loaded} />
        <ToastContainer position="bottom-center" />
        <div className="container-payment">
          <div className="payment">
            <h1 className="title text-left">Calendar Details</h1>
          </div>
        </div>
        <div className="!pt-3 !ml-12">
          <select
            id="engagement-dropdown"
            className="block w-auto border border-gray-300 rounded-md p-2"
            value={selectedValue}
            onChange={handleChange}
          >
            <option value="Employee Engagement">Employee Engagement</option>
            <option value="DEI">DEI</option>
          </select>
        </div>

        <div className="container !pt-3">
          <div className="tabs mb-5">
            <button
              className={`btn btn-default mr-3 ${activeTab === 1 ? "active btn-primary" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              Upload Thumbnail
            </button>
            <button
              className={`btn btn-default mr-3${activeTab === 2 ? "active btn-primary" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Add Occasions Data
            </button>
            <button
              className={`btn btn-default ${activeTab === 3 ? "active btn-primary" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Data Uploaded
            </button>
          </div>


          {activeTab === 1 && (
            allQuartersInfo && allQuartersInfo.length === 0 ? (

              <form onSubmit={submitQuarterImages} className="mx-auto w-full border-2 p-5 rounded-lg bg-white shadow-lg">
              
                <h2 className="text-2xl font-bold text-gray-900 text-center mb-6">Upload Quarter Images</h2>

                {rows.map((row, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8 shadow p-5 rounded-lg bg-gray-100"
                  >

                    <div className="relative z-0 w-full group">
                      <label htmlFor={`name-${index}`} className="block mb-2 text-lg font-bold text-gray-900">
                        Quarter {index + 1}
                      </label>
                    </div>


                    <div className="relative z-0 w-full group flex flex-col items-center">
                      <label htmlFor={`desktop-image-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Desktop Thumbnail
                      </label>
                      {row.image ? (
                        <img
                          src={URL.createObjectURL(row.image)}
                          alt="Preview"
                          className="w-24 h-24 object-cover rounded-md shadow mb-3 rounded-md"
                        />
                      ) : (
                        <div className="w-24 h-24 bg-gray-300 rounded-md flex items-center justify-center shadow mb-3">
                          <span className="text-gray-500 text-xs">No Image</span>
                        </div>
                      )}
                    </div>


                    <div className="relative z-0 w-full group">
                      <label htmlFor={`desktop-file-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Upload Desktop Thumbnail
                      </label>
                      <input
                        type="file"
                        id={`desktop-file-${index}`}
                        onChange={(e) => handleFileChange(index, e)}
                        className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        accept="image/*"
                      />
                      {errors[index]?.file && (
                        <p className="text-red-500 text-sm mt-2">{errors[index].file}</p>
                      )}
                    </div>


                    <div className="relative z-0 w-full group">
                      <label htmlFor={`color-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Select Color
                      </label>
                      <input
                        type="color"
                        id={`color-${index}`}
                        value={row.color || "#000000"}
                        onChange={(e) => handleColorChange(index, e)}
                        className="w-20 h-8 border-2 rounded-lg shadow"
                      />
                    </div>


                    <div className="relative z-0 w-full group flex flex-col items-center">
                      <label htmlFor={`desktop-image-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Mobile Thumbnail
                      </label>
                      {row.mobileimage ? (
                        <img
                          src={URL.createObjectURL(row.mobileimage)}
                          alt="Preview"
                          className="w-24 h-24 object-cover rounded-md shadow mb-3 rounded-md"
                        />
                      ) : (
                        <div className="w-24 h-24 bg-gray-300 rounded-md flex items-center justify-center shadow mb-3">
                          <span className="text-gray-500 text-xs">No Image</span>
                        </div>
                      )}
                    </div>


                    <div className="relative z-0 w-full group">
                      <label htmlFor={`mobile-file-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Upload Mobile Thumbnail
                      </label>
                      <input
                        type="file"
                        id={`mobile-file-${index}`}
                        onChange={(e) => handleMobileFileChange(index, e)}
                        className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        accept="image/*"
                      />
                      {errors[index]?.mobilefile && (
                        <p className="text-red-500 text-sm mt-2">{errors[index].mobilefile}</p>
                      )}
                    </div>
                  </div>
                ))}

                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </form>



            ) : (
              <div className="!bg-white p-3 rounded-md">
                {allQuartersInfo && allQuartersInfo.map((row, index) => (
                  <div key={index} className="grid grid-cols-1 md:grid-cols-5 md:gap-6 mb-5 border-b-2">

                    <div className="relative z-0 w-full mb-5 group">
                      <label
                        htmlFor={`name-${index}`}
                        className="block mb-2 text-xl font-medium text-gray-900 font-bold"
                      >
                        Quarter {row.name}
                      </label>
                      <label
                        htmlFor={`name-${index}`}
                        className="block mb-2 text-sm font-medium"
                      >
                        Update color
                      </label>
                      <input
                        type="color"
                        id={`color-${index}`}
                        name={`color-${index}`}
                        className="w-16 h-8 border border-gray-300 rounded cursor-pointer"
                        value={colors[index]}
                        onChange={(e) => handleColorChangeOnupdate(index, e.target.value)}
                      />
                    </div>

                    <div className="relative z-0 w-full mb-2 group flex flex-col items-center justify-center">
                      <label htmlFor={`image-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Desktop Thumbnail
                      </label>

                      <img
                        src={S3_BASE_URL + row.path}
                        alt="Preview"
                        className="w-24 h-24 object-cover mb-3 rounded-md"
                      />
                    </div>

                    <div className="relative z-0 w-full mb-5 group">
                      <label htmlFor={`file-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Upload New Thumbnail (Desktop)<br />
                        Size 251 x330
                      </label>
                      <input
                        type="file"
                        id={`file-${index}`}
                        onChange={(e) => handleFileChangeAfterUpload(row, e, 'desktop')}
                        className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        accept="image/*"
                      />
                    </div>

                    <div className="relative z-0 w-full mb-2 group flex flex-col items-center justify-center">
                      <label htmlFor={`image-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Mobile Thumbnail
                      </label>

                      <img
                        src={S3_BASE_URL + row.mobilePath}
                        alt="Preview"
                        className="w-24 h-24 object-cover mb-3 rounded-md"
                      />
                    </div>

                    <div className="relative z-0 w-full mb-5 group">
                      <label htmlFor={`file-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
                        Upload New Thumbnail (Mobile) <br />
                        Size 570 x 150
                      </label>
                      <input
                        type="file"
                        id={`file-${index}`}
                        onChange={(e) => handleFileChangeAfterUpload(row, e, 'mobile')}
                        className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        accept="image/*"
                      />
                    </div>
                  </div>
                ))}


                {allQuartersInfo && allQuartersInfo.some((row, index) => colors[index] !== row.color) && (
                  <button className="mt-3 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600" onClick={handleUpdateButtonClick}>
                    Update
                  </button>
                )}
              </div>

            )
          )}



          {activeTab === 2 && (
            <form className="mx-auto w-full p-5 rounded-lg bg-white" onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="w-full mb-5 group">
                  <label>Quarter</label>
                  <select
                    value={selectedQuarter}
                    onChange={handleQuarterChange}
                    className="h-auto text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="">Select Quarter</option>
                    {allQuartersInfo && allQuartersInfo.map((quarter) => (
                      <option key={quarter.id} value={quarter.id}>
                        Quarter {quarter.name}
                      </option>
                    ))}
                  </select>
                  {errorsOcasionForm.quarter && <p className="text-red-500 text-xs">{errorsOcasionForm.quarter}</p>}
                </div>
                <div className="w-full mb-5 group">
                  <label>Select Month</label>
                  <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="h-auto text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="">Select month </option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  {errorsOcasionForm.month && <p className="text-red-500 text-xs">{errorsOcasionForm.month}</p>}
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">

                <div className="w-full mb-5 group">
                  <label htmlFor="occasion-name" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Name
                  </label>
                  <input
                    type="text"
                    id="occasion-name"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    placeholder="Occasion Name"
                    value={occasionName}
                    onChange={(e) => setOccasionName(e.target.value)}

                  />
                  {errorsOcasionForm.occasionName && <p className="text-red-500 text-xs">{errorsOcasionForm.occasionName}</p>}
                </div>

                <div className="relative z-10 w-full mb-5 group">
                  <label htmlFor="select-games" className="block mb-2 text-sm font-medium text-gray-900">
                    Select Games
                  </label>
                  <Select
                    id="select-games"
                    classNamePrefix="react-select"
                    options={gameOptions}
                    menuPlacement="auto"
                    isMulti
                    placeholder="Select Games"
                    onChange={handleGameSelect}
                    value={selectedGames}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="from-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    From Date
                  </label>
                  <input
                    type="date"
                    id="from-date-input"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    value={occasionDate}
                    onChange={(e) => setOccasionDate(e.target.value)}
                    min={
                      selectedMonth
                        ? moment(`${selectedMonth} 1`, "MMMM D").startOf("month").format("YYYY-MM-DD")
                        : undefined
                    }
                    max={
                      selectedMonth
                        ? moment(`${selectedMonth} 1`, "MMMM D").add(1, 'month').endOf("month").format("YYYY-MM-DD")
                        : undefined
                    }
                  />
                  {errorsOcasionForm.occasionDate && <p className="text-red-500 text-xs">{errorsOcasionForm.occasionDate}</p>}
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="to-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    To Date
                  </label>
                  <input
                    type="date"
                    id="to-date-input"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    value={toDate}
                    onChange={(e) => setToOccasionDate(e.target.value)}
                    min={
                      selectedMonth
                        ? moment(`${selectedMonth} 1`, "MMMM D").startOf("month").format("YYYY-MM-DD")
                        : undefined
                    }
                    max={
                      selectedMonth
                        ? moment(`${selectedMonth} 1`, "MMMM D").add(1, 'month').endOf("month").format("YYYY-MM-DD")
                        : undefined
                    }
                  />
                  {errorsOcasionForm.toDate && <p className="text-red-500 text-xs">{errorsOcasionForm.toDate}</p>}
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">




                {/* <div className="relative z-0 w-full mb-5 group">
  <label htmlFor="occasion-date-input" className="block mb-2 text-sm font-medium text-gray-900">
    Occasion Date 
 
  </label>

  <input
type="date"
id="occasion-date-input"
className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
value={occasionDate}
onChange={(e) => setOccasionDate(e.target.value)}
min={
selectedMonth
? moment(`${selectedMonth} 1`, "MMMM D").startOf("month").format("YYYY-MM-DD")
: undefined
}
max={
selectedMonth
? moment(`${selectedMonth} 1`, "MMMM D").add(1, 'month').endOf("month").format("YYYY-MM-DD")
: undefined
}
disabled={!selectedMonth}
/>

  {errorsOcasionForm.occasionDate && <p className="text-red-500 text-xs">{errorsOcasionForm.occasionDate}</p>}
</div> */}
              </div>


              <div className="grid md:grid-cols-2 md:gap-6">

                <div className="w-full mb-5 group">
                  <label htmlFor="occasion-name" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Banner (Mobile)
                    <br />
                    Size 410 x 760
                  </label>
                  <input
                    type="file"
                    id="mobile-thumbnail"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    onChange={handleMobileThumbnailChange}
                  />
                  {errorsOcasionForm.mobileThumbnail && (
                    <p className="text-red-500 text-xs">{errorsOcasionForm.mobileThumbnail}</p>
                  )}
                </div>


                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="occasion-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Banner Preview (Mobile)
                  </label>
                  {mobileThumbnailPreview && (
                    <div className="mt-2">
                      <img src={mobileThumbnailPreview} alt="Mobile Thumbnail Preview" className="w-32 h-32 object-cover" />
                    </div>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">

                <div className="w-full mb-5 group">
                  <label htmlFor="occasion-name" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Banner (Desktop)
                    <br />
                    Size 1920 x 520
                  </label>
                  <input
                    type="file"
                    id="desktop-thumbnail"
                    className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-2"
                    onChange={handleDesktopThumbnailChange}
                  />
                  {errorsOcasionForm.desktopThumbnail && (
                    <p className="text-red-500 text-xs">{errorsOcasionForm.desktopThumbnail}</p>
                  )}

                </div>


                <div className="relative z-0 w-full mb-5 group">
                  <label htmlFor="occasion-date-input" className="block mb-2 text-sm font-medium text-gray-900">
                    Occasion Banner Preview (Desktop)
                  </label>
                  {desktopThumbnailPreview && (
                    <div className="mt-2">
                      <img src={desktopThumbnailPreview} alt="Desktop Thumbnail Preview" className="w-32 h-32 object-cover" />
                    </div>
                  )}

                </div>
              </div>



              <button
                type="submit"
                className="text-white btn btn-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                disabled={Object.keys(errors).length > 0}
              >
                Submit
              </button>
            </form>

          )}

          {activeTab === 3 && (
            <div className="container mx-auto !pt-3 bg-white p-0">

              {modifiedData && modifiedData.length === 0 && (
                <h2 className="text-center text-gray-500 text-2xl mt-12">No Data Found</h2>
              )}

              {modifiedData && modifiedData.map((quarterData, quarterIndex) => (
                <div key={quarterIndex} className="mb-6">
                  <h6 className="text-lg font-bold p-4">{quarterData.quarter}</h6>

                  <div className="ml-4 bg-white">
                    <table className="min-w-full table-auto border-collapse border-2 table-fixed">
                      <thead>
                        <tr>
                          <th className="border p-2 w-1/3">Month</th>
                          <th className="border p-2 w-2/3">Occasion Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quarterData.months.map((monthData, monthIndex) => (
                          <tr key={monthIndex} className="border-b-2 ">
                            <td className="p-2 align-baseline font-bold w-1/6 border">{monthData.month}</td>
                            <td className="p-2 w-5/6 border" colSpan={4}>
                              {monthData.occasions.length === 0 ? (
                                <p className="text-gray-500">No Data Found</p>
                              ) : (
                                <div className="w-full">
                                  {monthData.occasions.map((occasionData, occasionIndex) => (
                                    <div
                                      key={occasionIndex}
                                      className={`shadow rounded mb-3`}
                                    >
                                      <div className="flex items-center justify-between p-2 ">
                                        <button
                                          onClick={() => handleAccordionToggle(occasionIndex, monthData.month)}
                                          className="flex items-center justify-between w-full font-medium rtl:text-right text-gray-500 rounded mb-0"
                                          data-accordion-target={`#accordion-collapse-body-${occasionIndex}`}
                                          aria-expanded={openIndex === occasionIndex && openTitle === monthData.month ? "true" : "false"}
                                          aria-controls={`accordion-collapse-body-${occasionIndex}`}
                                        >
                                          <span className=" capitalize text-black">{occasionIndex + 1}.  {occasionData.occasion} </span>
                                          <svg
                                            data-accordion-icon
                                            className={`w-3 h-3 ${openIndex === occasionIndex && openTitle === monthData.month ? 'rotate-180' : ''} shrink-0`}
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 10 6"
                                          >
                                            <path
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M9 5 5 1 1 5"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      <div
                                        id={`accordion-collapse-body-${occasionIndex}`}
                                        className={openIndex === occasionIndex && openTitle === monthData.month ? "" : "hidden"}
                                        aria-labelledby={`accordion-collapse-heading-${occasionIndex}`}
                                      >
                                        <div className="p-5">
                                          <div className="grid grid-cols-1 gap-4">

                                            <div className="flex space-x-4">
                                              <div className="w-full">
                                                <label className="block text-sm font-medium text-gray-700">Occasion Name</label>
                                                <input
                                                  type="text"
                                                  value={occasionData.occasion}
                                                  onChange={(e) => handleInputChange_1(e, quarterIndex, monthIndex, occasionIndex, 'occasion')}
                                                  className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                />
                                              </div>
                                              <div className="w-full">
                                                <label className="block text-sm font-medium text-gray-700">Select Games</label>
                                                <Select
                                                  classNamePrefix="react-select"
                                                  className="w-full"
                                                  options={gameOptions1}
                                                  isMulti
                                                  placeholder="Select Games"
                                                  value={occasionData.games.map(game => ({ value: game.id, label: game.title }))}
                                                  onChange={(selectedGames) => handleGameSelect_1(selectedGames, quarterIndex, monthIndex, occasionIndex)}
                                                />
                                                {/* <label className="block text-sm font-medium text-gray-700">Occasion Date {monthData.month}</label>

                                                <input
                                                  type="date"
                                                  value={occasionData.date}
                                                  onChange={(e) =>
                                                    handleInputChange_1(e, quarterIndex, monthIndex, occasionIndex, "date")
                                                  }
                                                  className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                  min={moment(`${monthData.month} 1`, "MMMM D").startOf("month").format("YYYY-MM-DD")}
                                                  max={moment(`${monthData.month} 1`, "MMMM D").endOf("month").format("YYYY-MM-DD")}
                                                /> */}
                                              </div>
                                            </div>


                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">Occasion Banner (mobile) (410 x 760)</label>
                                                <input
                                                  type="file"
                                                  className="mt-1 block w-full px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  onChange={(e) => handleFileChange1(e, quarterIndex, monthIndex, occasionIndex, occasionData.mobileBanner, 'mobileBanner')}
                                                />
                                                <div>
                                                  <label className="block text-sm font-medium text-gray-700 mt-3">Mobile Preview</label>
                                                  <img
                                                    src={S3_BASE_URL + occasionData.mobileBanner}
                                                    alt="Preview"
                                                    className="w-full h-20 object-cover mb-3"
                                                    style={{
                                                      borderRadius: '4px',
                                                      cursor: 'pointer',
                                                      display: 'inline-flex',
                                                      padding: '8px',
                                                      background: 'rgb(226 85 105 / 34%)',
                                                    }}
                                                  />
                                                </div>
                                              </div>


                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">Occasion Banner (desktop) (1920 x 520)</label>
                                                <input
                                                  type="file"
                                                  className="mt-1 block w-full px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  onChange={(e) => handleFileChange1(e, quarterIndex, monthIndex, occasionIndex, occasionData.desktopBanner, 'desktopBanner')}
                                                />
                                                <div>
                                                  <label className="block text-sm font-medium text-gray-700 mt-3">Desktop Preview</label>
                                                  <img
                                                    src={S3_BASE_URL + occasionData.desktopBanner}
                                                    alt="Preview"
                                                    className="w-full h-20 object-cover mb-2"
                                                    style={{
                                                      borderRadius: '4px',
                                                      cursor: 'pointer',
                                                      display: 'inline-flex',
                                                      padding: '8px',
                                                      background: 'rgb(226 85 105 / 34%)',
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                              <div>
                                                <label className="block text-sm font-medium text-gray-700">From Date </label>

                                                <input
                                                  type="date"
                                                  value={occasionData.date}
                                                  onChange={(e) =>
                                                    handleInputChange_1(e, quarterIndex, monthIndex, occasionIndex, "date")
                                                  }
                                                  className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                  placeholder="Enter value"
                                                  min={moment(`${monthData.month} 1`, "MMMM D")
                                                  .startOf("month")
                                                  .format("YYYY-MM-DD")}
                                                max={moment(`${monthData.month} 1`, "MMMM D")
                                                  .add(1, "month")
                                                  .endOf("month")
                                                  .format("YYYY-MM-DD")}
                                                />
                                                <div>

                                                </div>
                                              </div>


                                              <div>

                                                <div>
                                                  <label className="block text-sm font-medium text-gray-700">To Date {occasionData.toDate}</label>

                                                  <input
                                                    type="date"
                                                    value={occasionData.toDate}
                                                    onChange={(e) =>
                                                      handleInputChange_1(e, quarterIndex, monthIndex, occasionIndex, "toDate")
                                                    }
                                                    className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                    placeholder="Enter value"
                                                    min={moment(`${monthData.month} 1`, "MMMM D")
                                                    .startOf("month")
                                                    .format("YYYY-MM-DD")}
                                                  max={moment(`${monthData.month} 1`, "MMMM D")
                                                    .add(1, "month")
                                                    .endOf("month")
                                                    .format("YYYY-MM-DD")}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <div>

                                              </div>

                                              <div className="flex space-x-4 mt-4">
                                                <button
                                                  className="btn bg-red-200 text-xs mr-2 rounded-sm px-4 py-2 shadow-md hover:bg-red-300 transition duration-200 ease-in-out transform hover:scale-105"
                                                  onClick={() => handleUpdate(quarterIndex, monthIndex, occasionIndex)}
                                                >
                                                  Update
                                                </button>
                                                <button
                                                  className="btn bg-green-200 text-xs px-4 py-2 rounded-sm shadow-md hover:bg-green-300 transition duration-200 ease-in-out transform hover:scale-105"
                                                  onClick={() => deleteOccasion(occasionData.id)}
                                                >
                                                  Delete
                                                </button>
                                              </div>

                                            </div>
                                          </div>
                                        </div>


                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>


                  </div>

                </div>
              ))}
            </div>



          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default CalendarAdmin;
