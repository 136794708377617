import React from 'react';

import './modal.css';
import Modal from './modal';

import submit from '../../assets/images/submit.gif';
import { Link } from 'react-router-dom';

const ViewDescriptionModal = ({ modalid, toggle, setOpenModal,description }) => {
  return (
    <Modal modalid={modalid} toggle={toggle}>
      <div className="modal-body">
        <div className="close-icon" data-dismiss="modal" onClick={() => setOpenModal(false)} aria-label="Close">
          <div className="close-btn-icon"></div>
        </div>
        <div className="thankyou-body contactingus">
         
          <h5 className='!text-left '>Description</h5>
          <span className='!p-0 !text-left'>{description} </span>
       
        </div>
      </div>
    </Modal>
  );
};
export default ViewDescriptionModal;
