import React, { useState, useEffect } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { logOut } from "../../redux/actions/userAction";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsAdmin, successAlert } from "../../helpers/helper";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { BASE_URL, decryptData, encryptData, failureAlert ,axiosApiInstance} from "../../helpers/helper";

const GenerateStaticToken = (props) => {
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    dispatch(getAllOrganisations(""));
  }, [dispatch]);

  const allOrganisationData = useSelector(
    (state) => state.allOrganisationData?.allOrganisationData?.data?.allOrganisationDetails || []
  );

  const options = allOrganisationData.map((org) => ({
    id: org.organizationId,
    label: org.organizationName,
    value: org.organizationId,
  }));

  const handleChange = (selectedOption) => {
    setSelectedId(selectedOption?.id || null);
    setToken("");
  };

  const generateToken = () => {
    if (selectedId) {
    
      generateTokenOnOrgId(selectedId);
    }
  };
  const generateTokenOnOrgId = async (orgId) => {
    try {
      const response = await axiosApiInstance.get(`${BASE_URL}/api/generate-token/getToken`, {
        params: { orgId }
      });

    

      if (response.data?.token && response.status === 200) {
        setToken(response.data.token);
        successAlert("Token generated successfully!");
      } else {
        failureAlert("Failed to generate token");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      failureAlert("Something went wrong");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    successAlert("Token copied!");
  };

  const signOut = async () => {
    await dispatch(logOut());
    props.history.push(isAdmin ? "/admin" : "/");
  };

  return (
    <div className="admin-homepage">
    <PageLayout
      sidebartitle=""
      active={"Generate Token"}
      category
      sideBarContents={sidebarContentAdmin}
      profile
      {...props}
      signOut={signOut}
      isAdmin={isAdmin}
    >
      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
  
      <div className="container-payment">
        <div className="payment">
          <h1 className="title text-left">Generate Token</h1>
        </div>
      </div>
  
      <div className="bg-white shadow-lg p-6 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Select Organisation</h2>
        <Select
          options={options}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          onChange={handleChange}
          placeholder="Choose an organisation..."
          className="mb-4 "  // Adjust the width here (e.g., w-1/2 for 50% width)
        />
  
        <button
          onClick={generateToken}
          disabled={!selectedId}
          className={` p-2 text-white font-bold rounded-lg transition block ${selectedId ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-400 cursor-not-allowed"}`}
        >
          Generate Token
        </button>
  
        {token && (
          <div className="mt-4 p-4 bg-gray-100 rounded-lg text-center overflow-hidden max-w-full">
            <p className="text-md font-mono text-gray-700 truncate">{token}</p>
            <button
              onClick={copyToClipboard}
              className="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-4 rounded"
            >
              Copy
            </button>
          </div>
        )}
      </div>
    </PageLayout>
  </div>
  
  );
};

export default GenerateStaticToken;
