import * as ActionTypes from "../constants/pointSystemConstants";

import { axiosApiInstance, BASE_URL, decryptData, encryptData, GetToken, GetUserType } from "../../helpers/helper";
import ROLES, { OrgRoles } from "../../helpers/userTypes";



const fetchTopThreeUsersByPoints = (activeFilter,organizationId) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_ALL_POINTS_REQUEST });
   try {
  
    var url = "/api/point_system/getAll?search=" + activeFilter + "&organizationid=" + organizationId;

    const { data, status } = await axiosApiInstance.get( BASE_URL + url);
     dispatch({
       type: ActionTypes.GET_ALL_POINTS_SUCCESS,
       payload: data,
     });
   } catch (error) {
     dispatch({
       type: ActionTypes.GET_ALL_POINTS_FAIL,
       payload: error.message,
     });
   }
};

const fetchTask = (activeFilter,userId) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_TASK_REQUEST });
   try {
    const capitalizedFilter = activeFilter.toUpperCase();
    var url = "/api/point_system/getTasks?task_type=" + capitalizedFilter + "&user_id=" + userId;

    const { data, status } = await axiosApiInstance.get( BASE_URL + url);
     dispatch({
       type: ActionTypes.GET_TASK_SUCCESS,
       payload: data,
     });
   } catch (error) {
     dispatch({
       type: ActionTypes.GET_TASK_FAIL,
       payload: error.message,
     });
   }
};

const fetchLoginStreakInfoforLoginUser = (userid) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_LOGIN_STREAK_REQUEST });
   try {
    var url = "/api/point_system/getLoginInfo?userid=" + userid;
    const { data, status } = await axiosApiInstance.get( BASE_URL + url);
     dispatch({
       type: ActionTypes.GET_LOGIN_STREAK_SUCCESS,
       payload: data,
     });
   } catch (error) {
     dispatch({
       type: ActionTypes.GET_LOGIN_STREAK_FAIL,
       payload: error.message,
     });
   }
};

 const addBadgeId = (badgeId) => async (dispatch) => {
  try {
  
    dispatch({
      type: ActionTypes.ADD_BADGE_ID,
      payload: badgeId,
    });
  } catch (error) {
    console.error("Error adding badge ID:", error);
  }
};

export {
  fetchTopThreeUsersByPoints,
  fetchLoginStreakInfoforLoginUser,
  addBadgeId,
  fetchTask
  };
