import React, { useEffect, useState, useRef } from 'react';
import './dashboard.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Helmet } from 'react-helmet-async';
import target from "../../assets/icons/Target.png";
import participant from "../../assets/icons/icon_participants.svg";
import time from "../../assets/icons/icon_time.svg";
import coin from "../../assets/icons/Coin.png";
import teamlogo from "../../assets/backgrounds/team logo.png";
import dummygame from "../../assets/icons/gamedumy.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getAllTeamsReport, getOrgTeamsGameSessions, getTeamsGamesReport } from '../../redux/actions/teamAction';
import { S3_BASE_URL } from '../../helpers/helper';
import { getGameServerOtp } from '../../redux/actions/gameDetailAction';
import LoadingComponent from '../../components/loader/LoadingComponent';
const TeamDetailsPointSystem = (props) => {
  const cards = [
    { title: 'Back To School', description: 'Description for Card 1' },
    { title: 'Back To School', description: 'Description for Card 2' },
    { title: 'Back To School', description: 'Description for Card 3' },
    { title: 'Back To School', description: 'Description for Card 4' },
    { title: 'Back To School', description: 'Description for Card 5' },
    { title: 'Back To School', description: 'Description for Card 6' },
    { title: 'Back To School', description: 'Description for Card 7' },
    { title: 'Back To School', description: 'Description for Card 8' },
  ];

  const responsive = {
    superLargeDesktop: {
      // screens > 3000px
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1380 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1380, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const AllTeamsData = useSelector((state) => state.getAllTeamsReportsInfo);
  const { allTeamReport } = AllTeamsData;
  const UserInfo = useSelector(state => state.getUser)
  const { userInfo } = UserInfo;
  const OrgTeamSessions = useSelector((state) => state.getOrgTeamsGameSession)
  const { teamSessions } = OrgTeamSessions;
  const GameServerOtp = useSelector(state => state.gameServerOtp);
  const { gameServerOtp } = GameServerOtp;


  const [loaded, setLoaded] = useState(true);
  const [teamsReports, setTeamsReport] = useState([])
  const [userTeamInfo, setUserTeamInfo] = useState({})
  const [gameSessions, setGameSessions] = useState([])

  const [joinPressed, setJoinPressed] = useState(false);



  const dispatch = useDispatch();

  const tstClick = async (e) => {
    e.preventDefault()
    dispatch(getAllTeamsReport())

  }
  useEffect(() => {
    if (allTeamReport && allTeamReport?.data && allTeamReport?.data?.message === 'Successfully fetched teams leaderboard info' && allTeamReport?.data?.data) {
    }
    else {
      dispatch(getAllTeamsReport())
    }
    if (!teamSessions && teamSessions?.message !== "sessions fetch sucessfully") {
      dispatch(getOrgTeamsGameSessions("Active Sessions"))
    }
  }, [])

  useEffect(() => {
    if (allTeamReport && allTeamReport?.data && allTeamReport?.data?.message === 'Successfully fetched teams leaderboard info' && allTeamReport?.data?.data) {
      setTeamsReport(allTeamReport?.data?.data);

    }
    else {
      setTeamsReport([])
    }

    if (teamSessions && teamSessions.message === "sessions fetch sucessfully") {
      setGameSessions(teamSessions.data)
    }

  }, [AllTeamsData, OrgTeamSessions])

  useEffect(() => {
    if (teamsReports && teamsReports.length > 0 && userInfo && userInfo?.data) {

      const userTeam = teamsReports.find((data) => data.id === userInfo?.data?.organisationTeamId)
      if (userTeam) {
        setUserTeamInfo(userTeam)
      }
      else {
        setUserTeamInfo({})
      }

    }
  }, [userInfo, teamsReports])

  useEffect(() => {
    if (joinPressed && gameServerOtp && gameServerOtp.data) {
      setJoinPressed(false);
      window.location.replace(gameServerOtp.data.redirectUrl);
    }
  }, [gameServerOtp, joinPressed])

  const capitalizeFirstAlpabate = (name) => {
    if (typeof name !== 'string' || name.length === 0) {
      return '';
    }
    let spaceTrimName = name
    return spaceTrimName.charAt(0).toUpperCase() + spaceTrimName.slice(1).toLowerCase();
  }

  function calculateTotalPoints(objectsArray) {
    let totalPoints = 0;
    if (objectsArray && objectsArray.length === 0) {
      return 0;
    }
    for (const obj of objectsArray) {
      if (obj.totalPoints && obj.totalPoints > 0) {
        totalPoints += obj.totalPoints;
      }
    }

    return totalPoints;
  }



  const handleJoinGame = async (sessionId, from, to, sessionGameId) => {
    if ((new Date(from) > new Date()) || (new Date(to) < new Date())) {
      return;
    }
    setLoaded(false);
    const response = await dispatch(getGameServerOtp(sessionGameId, sessionId));
    if (response && response.status === 200) {
      setJoinPressed(true);
    }
    else {
      setLoaded(true);
    }
  }

  const CustomArrow = ({ direction }) => {
    return (
      <div
        className={`absolute top-1/2 transform -translate-y-1/2 ${direction === "left" ? "left-0" : "right-0"
          } z-10 bg-black text-white p-2 rounded-full cursor-pointer`}
        style={{ left: direction === "left" ? "-0px" : "auto", right: direction === "right" ? "-0px" : "auto" }}
      >
        {direction === "left" ? "<" : ">"}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start w-full">
      <Helmet>
        <title>Teams</title>
        <meta name="description" content="Badges" />
        <meta name="keywords" content="Badges" />
      </Helmet>

      <LoadingComponent loaded={loaded} />
      <div className="w-full">
        <h1 className="mb-4 font-semibold text-center text-xl lg:text-left"
          onClick={tstClick}
        >Teams</h1>

        <div className="flex flex-wrap w-full">

          <div className="lg:mr-5 mb-6 sm:mb-0 p-0 w-full sm:w-[40%]">
            <div className="bg-white p-6 whitebgwithborder">
              <div className="flex sm:flex-row flex-col justify-between mb-4">
                <div className="text-left">
                  <h2 className="font-semibold text-[#23282E] text-lg">
                    {userInfo && userInfo?.data?.firstName
                      ? capitalizeFirstAlpabate(userInfo?.data?.firstName)
                      : ''}{' '}
                    -{' '}
                    <span className="highlight">
                      {userTeamInfo ? capitalizeFirstAlpabate(userTeamInfo?.name) : ''}
                    </span>
                  </h2>
                </div>
                <div className="sm:text-right flex sm:justify-end items-center mt-2 sm:mt-0 text-left">
                  <h2 className="font-semibold text-[#23282E] text-lg">
                    {userTeamInfo && userTeamInfo?.totalTeamsPoints
                      ? userTeamInfo?.totalTeamsPoints
                      : 0}{' '}
                    Score
                  </h2>
                  <img src={coin} alt="Example" className="ml-2 w-8 h-8" />
                </div>
              </div>

              <div
                className="p-2 teamDetailsListScroll"
                style={{ backgroundColor: '#E1F2FC80', borderRadius: '10px' }}
              >
                <table className="min-w-full">
                  <tbody>
                    {teamsReports &&
                      teamsReports.length > 0 &&
                      teamsReports.map((team, index) => (
                        <tr className="border-b">
                          <td className="p-3">{index + 1}</td>
                          <td className="p-3">
                            <img
                              src={
                                team?.teamLogo
                                  ? S3_BASE_URL + team?.teamLogo
                                  : teamlogo
                              }
                              alt="Example"
                              className="rounded-full w-8 h-8 object-fill"
                            />
                          </td>
                          <td className="p-3 font-bold text-[#23282E]">
                            {team?.name
                              ? capitalizeFirstAlpabate(team?.name)
                              : ''}
                          </td>
                          <td className="text-right p-3 font-bold text-[#23282E]">
                            {team?.totalTeamsPoints
                              ? team?.totalTeamsPoints
                              : 0}{' '}
                            Score
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>


          <div className="relative p-0 w-full sm:w-[57%]">
            <div className="bg-white p-6 whitebgwithborder">
              <div className="flex justify-between mb-4">
                <div className="text-left">
                  <h2 className="font-semibold text-[#23282E] text-lg">
                    Schedule<span className="highlight"> Games</span>
                  </h2>
                </div>
                <div className="text-right flex items-center">
                  <button className="border-none font-bold text-gray-800 underline">
                    View All
                  </button>
                </div>
              </div>
              <Carousel
                responsive={responsive}
                infinite={true}
                arrows={true}
                className="w-full carousel-container"
              >
                {gameSessions &&
                  gameSessions.length > 0 &&
                  gameSessions.map((session, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col justify-between bg-gray-200 shadow-lg mr-4 p-4 rounded-lg w-[200px] h-[300px]"
                      style={{
                        backgroundImage: `url(${session?.organizationGame &&
                          session?.organizationGame?.game?.coverMedia[1]
                          ? S3_BASE_URL +
                          session?.organizationGame?.game?.coverMedia[1]
                          : dummygame
                          })`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    >
                      <div className="top-0 left-0 absolute bg-gradient-to-t from-black via-transparent to-transparent rounded-lg w-full h-full"></div>
                      <div className="bottom-4 left-1/2 absolute p-2 w-full text-center transform -translate-x-1/2">
                        <h3 className="mb-2 font-semibold text-sm text-white">
                          {session?.organizationGame?.game?.title
                            ? capitalizeFirstAlpabate(
                              session?.organizationGame?.game?.title
                            )
                            : ''}
                        </h3>
                        <div className="flex justify-center space-x-2 mb-2">
                          {session?.organizationGame?.game &&
                            session?.organizationGame?.game?.maxPlayers ? (
                            <div className="flex items-center bg-white shadow-md text-black playcardSubitem">
                              <img
                                src={participant}
                                alt="Icon"
                                className="mr-2 w-3 h-3"
                              />
                              <span className="text-[#23282E] text-sm">
                                {session?.organizationGame?.game?.maxPlayers
                                  ? session?.organizationGame?.game?.maxPlayers
                                  : 0}
                              </span>
                            </div>
                          ) : null}
                          {session?.organizationGame?.game &&
                            session?.organizationGame?.game?.duration ? (
                            <div className="flex items-center bg-white shadow-md text-black playcardSubitem">
                              <img
                                src={time}
                                alt="Icon"
                                className="mr-2 w-3 h-3"
                              />
                              <span className="text-[#23282E] text-sm">
                                {session?.organizationGame?.game?.duration}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <button
                          className="bg-blue-600 hover:bg-blue-700 !mt-0 px-4 py-2 rounded-lg min-w-[150px] transition duration-200 btn btn-primary ease-in-out playNowBtn"
                          onClick={(e) =>
                            handleJoinGame(
                              session.id,
                              session.scheduleFrom,
                              session.scheduleTo,
                              session.organizationGame.gameId
                            )
                          }
                          disabled={session.mode === 'ANYONE'}
                        >
                          Play Now
                        </button>
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>

        <div className="sm:block relative hidden bg-white p-0 whitebgwithborder w-full">
          <div className="flex justify-between items-center mt-6 p-6">
            <div className="flex items-center space-x-4">
              <img
                src={teamlogo}
                alt="Logo"
                className="w-12 h-12"
              />
              <div>
                <h2 class="font-semibold text-[#23282E] text-lg">{userInfo && userInfo?.data && userInfo?.data?.firstName ? capitalizeFirstAlpabate(userInfo?.data?.firstName) : ''} - <span class="highlight">{userTeamInfo ? capitalizeFirstAlpabate(userTeamInfo?.name) : ''}</span></h2>

                <p className="text-[#E25569] text-sm">
                  "The green bar signifies that the teammate is captain"
                </p>
              </div>
            </div>
            <h2 className="font-semibold text-[#23282E] text-lg">Total Team Members - {userTeamInfo && userTeamInfo?.members && userTeamInfo?.members?.length > 0 ? userTeamInfo?.members?.length : 0}</h2>
          </div>
          <div className="overflow-x-auto">
            <div className="space-y-4 p-6 !pt-0 w-full">

              <div className="flex bg-[#E1F2FC80] rounded-[10px]">
                <div className="flex-1 p-4 font-bold">No.</div>
                <div className="flex-1 p-4 font-bold">Name</div>
                <div className="flex-1 p-4 font-bold">Email</div>
                <div className="flex-1 p-4 font-bold">Score</div>
                <div className="flex-1 p-4 font-bold">No of Games Played</div>
              </div>


              {
                userTeamInfo && userTeamInfo?.members && userTeamInfo?.members?.length > 0 && userTeamInfo?.members.map((member, index) => (
                  <div className={`flex rounded-[10px]  ${userTeamInfo?.teamCaptainId && userTeamInfo?.teamCaptainId === member?.id ? 'captainRow' : 'teamDetailsBorder'}`}>
                    <div className="flex-1 p-4 font-bold text-[#23282E]">{index + 1}</div>
                    <div className="flex-1 p-4 font-bold text-[#23282E]">{member?.firstName ? capitalizeFirstAlpabate(member?.firstName) : '' + " " + member?.lastName ? capitalizeFirstAlpabate(member?.lastName) : ''}</div>
                    <div className="flex-1 p-4 font-bold text-[#23282E] truncate">{member?.email ? member?.email : ''}</div>
                    <div className="flex flex-1 items-center space-x-2 p-4 font-bold">
                      <span>{member?.totalPoints ? member?.totalPoints : 0}</span>
                      <img src={coin} alt="Icon" className="w-6 h-6" />

                    </div>

                    <div className="flex-1 p-4 font-bold">{member?.playedSessionCount ? member?.playedSessionCount : 0}/{member?.totalSessionCount ? member?.totalSessionCount : 0}</div>
                  </div>
                ))
              }

            </div>

          </div>




        </div>


        {/* <div className="block flex flex-col items-start sm:hidden mt-6 p-4 whitebgwithborder">
          <div className="flex items-center space-x-4">
            <img src={teamlogo} alt="Example" className="w-14 h-14" />
            <div className="flex flex-col ml-4">
              <h2 className="font-semibold text-[#23282E] text-lg">{userTeamInfo ? capitalizeFirstAlpabate(userTeamInfo?.title) : ''} - <span className='highlight'>Details</span></h2>
              <p className="mt-2 text-[#E25569] text-sm">  "The green bar signifies that the teammate is captain"</p>
            </div>
          </div>
          <h2 className="mt-4 ml-4 font-semibold text-[#23282E] text-lg">Total Team Members - {userTeamInfo && userTeamInfo?.members && userTeamInfo?.members?.length > 0 ? userTeamInfo?.members?.length : 0}</h2>
          <div className="gap-4 grid grid-cols-1 mt-4 w-full">

            {
              userTeamInfo && userTeamInfo?.members && userTeamInfo?.members?.length > 0 && userTeamInfo?.members.map((member, index) => (

                <div key={index} className={` rounded-[20px] p-3 ${userTeamInfo?.teamCaptainId && userTeamInfo?.teamCaptainId === member?.id ? 'captainRow' : 'teamDetailsBorder'}`}>
                  <div className="flex justify-between items-center border-[#707070] p-3 border-b">
                    <div className="flex flex-col">
                      <p className="teamdetailsmobileTitle">{`${index + 1}. ${member?.firstName ? capitalizeFirstAlpabate(member?.firstName) : '' + " " + member?.lastName ? capitalizeFirstAlpabate(member?.lastName) : ''}`}</p>
                      <p className="opacity-70 teamdetailsmobileTitle">{member?.email}</p>
                    </div>

                    <div className="flex space-x-2">
                      <p className="teamdetailsmobileTitle">{member.totalPoints}</p>
                      <img src={coin} alt="Example" className="mt-[-4px] w-8 h-8" />
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="flex flex-col">
                      <p className="teamdetailsmobileTitle">No of Games Played - {member?.playedSessionCount ? member?.playedSessionCount : 0}/{member?.totalSessionCount ? member?.totalSessionCount : 0}</p>
                    </div>
                  </div>
                </div>
              ))
            }

          </div>
        </div> */}
        <div className="flex flex-col items-start sm:hidden mt-6 p-4 whitebgwithborder">
          <div className="flex items-center space-x-4">
            <img src={teamlogo} alt="Example" className="w-14 h-14" />
            <div className="flex flex-col ml-4">
              <h2 className="font-semibold text-[#23282E] text-lg">
                {userInfo && userInfo?.data && userInfo?.data?.firstName ? capitalizeFirstAlpabate(userInfo?.data?.firstName) : ''} - <span className="highlight">{userTeamInfo ? capitalizeFirstAlpabate(userTeamInfo?.name) : ''}</span>
              </h2>
              <p className="mt-2 text-[#E25569] text-sm">
                "The green bar signifies that the teammate is captain"
              </p>
            </div>
          </div>
          <h2 className="mt-4 ml-4 font-semibold text-[#23282E] text-lg">
            Total Team Members - {userTeamInfo?.members?.length > 0 ? userTeamInfo.members.length : 0}
          </h2>
          <div className="gap-4 grid grid-cols-1 mt-4 w-full">
            {userTeamInfo?.members?.map((member, index) => (
              <div
                key={index}
                className={`rounded-[20px] p-3 ${userTeamInfo?.teamCaptainId === member?.id ? 'captainRow' : 'teamDetailsBorder'
                  }`}
              >
                <div className="flex justify-between items-center border-[#707070] p-3 border-b">
                  <div className="flex flex-col">
                    <p className="teamdetailsmobileTitle">
                      {`${index + 1}. ${member?.firstName ? capitalizeFirstAlpabate(member?.firstName) : ''} ${member?.lastName ? capitalizeFirstAlpabate(member?.lastName) : ''
                        }`}
                    </p>
                    <p className="opacity-70 w-full text-sm truncate">{member?.email}</p>
                  </div>

                  <div className="flex space-x-2">
                    <p className="teamdetailsmobileTitle">{member.totalPoints}</p>
                    <img src={coin} alt="Example" className="mt-[-4px] w-8 h-8" />
                  </div>
                </div>

                <div className="p-4">
                  <div className="flex flex-col">
                    <p className="teamdetailsmobileTitle">
                      No of Games Played - {member?.playedSessionCount || 0}/{member?.totalSessionCount || 0}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>

    </div>


  );
};

export default TeamDetailsPointSystem;

