import React, { useEffect, useState, useRef } from 'react';
import './dashboard.css'; // Include your styles here
import { uploadFile } from "../../redux/actions/commonActions";
import { BASE_URL, S3_BASE_URL, failureAlert, axiosApiInstance, successAlert } from "../../helpers/helper";
import DataTable from "react-data-table-component";
import { getAllFeatureBanners } from "../../redux/actions/featureBannerActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";
import active from "../../assets/icons/Most-Active-User.png";
import categorySpecialist from "../../assets/icons/Category-Specialist.png";
import consistentPlayer from "../../assets/icons/Consistent-Player.png";
import starfilled from "../../assets/icons/starfilled.jpg";
import starempty from "../../assets/icons/Starempty.png";
import vector from "../../assets/icons/Vector.png";
import target from "../../assets/icons/Target.png";
import coin from "../../assets/icons/Coin.png";
import crown from "../../assets/icons/crown.png";
import dummy from "../../assets/icons/dummyuser.png";
import mask from "../../assets/icons/confetti-min.gif";
import inactivebadge from "../../assets/icons/Badge_Disable.png";
import startbadge from "../../assets/icons/StarBadge.png";
import categoryExplorer from "../../assets/icons/Category-Explorer.png";
import EventEnthusiast from "../../assets/icons/Event-Enthusiast.png";
import FeedbackGuru from "../../assets/icons/Feedback-Guru.png";
import LuckyDrawMaster from "../../assets/icons/Lucky-Draw-Master.png";
import MilestoneChampion from "../../assets/icons/Milestone-Champion.png";
import mostActiveuser from "../../assets/icons/Most-Active-User.png";
import ParticipationChamp from "../../assets/icons/Participation-Champ.png";
import PointHoarder from "../../assets/icons/Point-Hoarder.png";
import RisingStar from "../../assets/icons/Rising-Star.png";
import SuperProfileCompleter from "../../assets/icons/Super-Profile-Completer.png";
import TeamBuilder from "../../assets/icons/Team-Builder.png";
import winningstrek from "../../assets/icons/Winning-Streak.png";
import { fetchTopThreeUsersByPoints, fetchLoginStreakInfoforLoginUser, addBadgeId, fetchTask } from "../../redux/actions/pointSystemActions";
import { getUserPointsInfo } from '../../redux/actions/userAction';
import { Player } from "@lottiefiles/react-lottie-player";
import lightFile from "./light-animation.json"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import progressImage from "../../assets/icons/image400.png";
import progressImage1 from "../../assets/icons/progressImg.png";
import nodataFound from "../../assets/icons/nodatafound.png";
import moment from "moment";
import { getAllTeamsReport, getTeamsGamesReport } from '../../redux/actions/teamAction';

const PointSystem = ({ userInfo }) => {
  const userTasks = {
    "Daily": [
      {
        taskName: 'Participate in a game and finish in the top 3',
        points: 200
      },
      {
        taskName: 'Participate in a game and finish in the top 5',
        points: 100
      },
      {
        taskName: 'Participate in a game of today',
        points: 50
      },
      {
        taskName: 'Play 2 games in a day',
        points: 75
      },
      {
        taskName: 'Achieve a high score in a single-player game',
        points: 100
      },
    ],
    "Weekly": [
      {
        taskName: 'Participate in games on 3 or more days this week',
        points: 250
      },
      {
        taskName: 'Finish in the top 10 in at least 2 games this week',
        points: 150
      },
      {
        taskName: 'Join a Team game scheduled',
        points: 150
      },
      {
        taskName: 'Participate in a game under an experience',
        points: 100
      },
      {
        taskName: 'Play a game in 2 different experience',
        points: 200
      },
    ],
    "Monthly": [
      {
        taskName: 'Participate in team games during the month',
        points: 500
      },
      {
        taskName: 'Maintain a top 10 position on the leaderboard throughout the month',
        points: 1000
      },
      {
        taskName: 'Participate in all games under 1 experience',
        points: 1000
      },
      {
        taskName: 'Play at least 1 game in every experience during the month',
        points: 1000
      },
      {
        taskName: 'Maintain a daily participation streak for 7 consecutive days',
        points: 500
      },
    ]
  }

  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const buttonRefTask = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTask, setIsOpenTask] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefTask = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState("Daily");
  const [selectedFilterTask, setSelectedFilterTask] = useState("Daily");

  const [winnerPointsArr, SetWinnerArr] = useState([]);
  const [allTasks, setTasks] = useState([]);
  const [loginStreakArr, SetLoginStreakArr] = useState([]);
  const toggleDropdown = () => setIsOpen(prevState => !prevState);
  const toggleDropdownForTask = () => setIsOpenTask(prevState => !prevState);
  const pointsData = useSelector((state) => state.allPoints);
  const task = useSelector((state) => state.tasks);
  const loginStreak = useSelector((state) => state.loginStreak);
  const UserPointsInfo = useSelector(state => state.getUserPointsInfo);
  const { userPointsInfo } = UserPointsInfo

  const AllTeamsData = useSelector((state) => state.getAllTeamsReportsInfo);
  const { allTeamReport } = AllTeamsData;
  const [teamsReports, setTeamsReport] = useState([])
  const [userTeamInfo, setUserTeamInfo] = useState({})

  const [loaded, setLoaded] = useState(false);
  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsOpen(false);
  };

  useEffect(() => {
    if (allTeamReport && allTeamReport?.data && allTeamReport?.data?.message === 'Successfully fetched teams leaderboard info' && allTeamReport?.data?.data) {
    }
    else {
      dispatch(getAllTeamsReport())
    }
  }, [])

  useEffect(() => {

    if (allTeamReport && allTeamReport?.data && allTeamReport?.data?.message === 'Successfully fetched teams leaderboard info' && allTeamReport?.data?.data) {

      setTeamsReport(allTeamReport?.data?.data);

    }
    else {
      setTeamsReport([])
      setUserTeamInfo({})
    }

  }, [AllTeamsData])

  useEffect(() => {
    if (teamsReports && teamsReports.length > 0 && userInfo && userInfo?.data) {
      const userTeam = teamsReports.find((data) => data.id === userInfo?.data?.organisationTeamId)
      if (userTeam) {
        setUserTeamInfo(userTeam)
      }
      else {
        setUserTeamInfo({})
      }

    }
    else {
      setUserTeamInfo({})

    }

  }, [userInfo, teamsReports])


  const handleFilterSelectTask = (filter) => {
    setSelectedFilterTask(filter);
    setIsOpenTask(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    // if (dropdownRefTask.current && !dropdownRefTask.current.contains(event.target) &&
    //   buttonRefTask.current && !buttonRefTask.current.contains(event.target)) {
    //   setIsOpenTask(false);
    // }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {



    dispatch(fetchTopThreeUsersByPoints(selectedFilter, userInfo.data.organizationId));
  }, [selectedFilter]);

  useEffect(() => {

    dispatch(fetchTask(selectedFilterTask, userInfo.data.id));
  }, [selectedFilterTask]);

  useEffect(() => {
    if (pointsData && pointsData?.topThreeUsers) {
      const sortedUsers = sortUsersByTotalPoints(pointsData?.topThreeUsers);
      SetWinnerArr(pointsData?.topThreeUsers.length > 0 ? sortedUsers : [])
    }

  }, [pointsData])


  useEffect(() => {
    localStorage.setItem('badgeIds', JSON.stringify([]));
  }
    , [])

  useEffect(() => {

    if (task) {
      setTasks(task.tasks)

    }
  }, [task])


  function sortUsersByTotalPoints(users) {
    const sortedUsers = [...users].sort((a, b) => b.totalPoints - a.totalPoints);
    if (sortedUsers.length >= 3) {
      return sortedUsers.map((user, index) => {
        return { ...user, rankKey: index + 1 };
      });
    }
    return sortedUsers;
  }
  function getDatesFromCurrentWeek(dates, isoWeek = true) {
    // Get start and end of the current week
    const startOfWeek = isoWeek ? moment().startOf('isoWeek') : moment().startOf('week');
    const endOfWeek = isoWeek ? moment().endOf('isoWeek') : moment().endOf('week');

    // Filter the dates
    return dates?.filter(date => {
      const momentDate = moment(date, 'YYYY-MM-DD');
      return momentDate.isBetween(startOfWeek, endOfWeek, null, '[]'); // Inclusive range
    });
  }
  useEffect(() => {
    if (loginStreak) {
      const { loginStreakData } = loginStreak;
      const { isConsistentPlayer, hasRatedAtLeastFiveGames, mostActiveUser } = loginStreakData || {};

      const filteredDates = getDatesFromCurrentWeek(loginStreakData?.data);
      SetLoginStreakArr(filteredDates);
      if (isConsistentPlayer) {
        handleTaskCompletion(4);
      }
      if (hasRatedAtLeastFiveGames) {
        handleTaskCompletion(8);
      }
      if (mostActiveUser) {
        handleTaskCompletion(12);
      }
    }
  }, [loginStreak]);

  const [loadedPoints, setLoadedPoints] = useState(false);
  const [loadedTask, setLoadedTask] = useState(false);
  const [loadedUserPoints, setLoadedUserPoints] = useState(false);
  useEffect(() => {
    if (pointsData?.loading) {
      setLoadedPoints(false);
      setLoaded(false)
    } else {
      setLoadedPoints(true);
      setLoaded(true)
    }

    if (task?.loading) {
      setLoadedTask(false);
      setLoaded(false)
    } else {
      setLoadedTask(true);
      setLoaded(true)
    }

    if (UserPointsInfo?.loading) {
      setLoadedUserPoints(false);
      setLoaded(false)
    } else {
      setLoadedUserPoints(true);
      setLoaded(true)
    }
  }, [pointsData, task, UserPointsInfo]);


  function calculateProfileCompletion(profileCompletionDetailsArray) {
    if (profileCompletionDetailsArray && profileCompletionDetailsArray.length !== 0) {
      const requiredKeys = ["name", "profileImg", "phoneNumber"];
      const totalKeys = requiredKeys.length;

      const presentKeys = new Set();

      profileCompletionDetailsArray.forEach((item) => {
        requiredKeys.forEach((key) => {
          if (item.hasOwnProperty(key)) {
            presentKeys.add(key);
          }
        });
      });

      const completionPercentage = (presentKeys.size / totalKeys) * 100;

      return Math.floor(completionPercentage);
    }
    else {
      return 0
    }

  }

  const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - dayOfWeek);

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      weekDates.push(day.toISOString().split('T')[0]);
    }
    return weekDates;
  };

  useEffect(() => {
    if (userInfo && userInfo.data) {

      dispatch(fetchLoginStreakInfoforLoginUser(userInfo.data.id));
      dispatch(getUserPointsInfo())
    }
  }, [userInfo])

  useEffect(() => {
    if (userPointsInfo && userPointsInfo?.data) {

      if (userPointsInfo && userPointsInfo?.data?.userSessionPoints) {

        let userSessionsPointsData = userPointsInfo?.data?.userSessionPoints

        if (userSessionsPointsData && userSessionsPointsData.length >= 10) {
          handleTaskCompletion(1)
        }

        if (userSessionsPointsData && userSessionsPointsData.length > 0) {

          let sessionWon = userSessionsPointsData.filter((data) => data.points === 25)

          if (sessionWon && sessionWon.length >= 5) {
            handleTaskCompletion(2)
          }

          let topThreeSessions = userSessionsPointsData.filter((data) => data.points !== 5)

          if (topThreeSessions && topThreeSessions.length >= 10) {
            handleTaskCompletion(3)
          }

        }

      }

      if (userPointsInfo && userPointsInfo?.data?.profileCompletionDetails && userPointsInfo?.data?.profileCompletionDetails?.length > 0) {
        let userProfilePointsData = calculateProfileCompletion(userPointsInfo?.data?.profileCompletionDetails)

        if (userProfilePointsData && userProfilePointsData >= 100) {
          handleTaskCompletion(5)
        }

      }

      if (userPointsInfo && userPointsInfo?.data?.userTeamsPoints && userPointsInfo?.data?.userTeamsPoints?.length > 0) {
        handleTaskCompletion(6)
      }
      if (userPointsInfo && userPointsInfo?.data?.userTeamscaptainPoints && userPointsInfo?.data?.userTeamscaptainPoints?.length > 0) {
        handleTaskCompletion(7)
      }

      if (userPointsInfo && userPointsInfo?.data?.categoryPoints && userPointsInfo?.data?.categoryPoints?.length > 0) {
        handleTaskCompletion(13)
      }

      if (userPointsInfo && userPointsInfo?.data?.totalPoints && userPointsInfo?.data?.totalPoints >= 500) {
        handleTaskCompletion(14)
      }

      if (userPointsInfo && userPointsInfo?.data?.totalPoints && userPointsInfo?.data?.totalPoints >= 1000) {
        handleTaskCompletion(15)
      }

    }

  }, [UserPointsInfo])


  const currentWeekDates = getCurrentWeekDates();

  const daysOfWeek = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];
  const [badges, setBadges] = useState([
    { id: 1, src: ParticipationChamp, alt: "Participation Champ", show: false, description: "Awarded for participating in 10 games." },
    { id: 2, src: winningstrek, alt: "Winning Streak", show: false, description: "Achieved by winning first place in 5 games" },
    { id: 3, src: RisingStar, alt: "Rising Star", show: false, description: "Earned by finishing in the top 3 positions in 10 games" },
    { id: 4, src: consistentPlayer, alt: "Consistent Player", show: false, description: "Consistent Player" },
    { id: 5, src: SuperProfileCompleter, alt: "Super Profile Completer", show: false, description: "Awarded for completing the profile 100%" },
    { id: 6, src: TeamBuilder, alt: "Team Builder", show: false, description: "Earned for creating your first team" },
    { id: 7, src: active, alt: "Leadership Ace", show: false, description: "Awarded for appointing a captain in your team" },
    { id: 8, src: FeedbackGuru, alt: "Feedback Guru", show: false, description: "Achieved by submitting feedback for 5 different games." },
    { id: 9, src: categoryExplorer, alt: "Category Explorer", show: false, description: " Awarded for participating in at least one game from all categories" },
    // { id: 10, src: EventEnthusiast, alt: "Event Enthusiast", show: false, description: " Earned by attending 5 live streams" },
    // { id: 11, src: LuckyDrawMaster, alt: "Lucky Draw Master", show: false, description: "Awarded for redeeming your first raffle ticket" },
    { id: 12, src: mostActiveuser, alt: "Most Active User", show: false, description: "Badge awarded for being recognized as the most active user in a week." },
    { id: 13, src: categorySpecialist, alt: "Category Specialist", show: false, description: "Awarded for earning 100 points in each category streak" },
    { id: 14, src: PointHoarder, alt: "Point Hoarder", show: false, description: "Achieved by accumulating 500 points" },
    { id: 15, src: MilestoneChampion, alt: "Milestone Champion", show: false, description: "Awarded for earning 1,000 cumulative points" },
  ]);
  const addBadgeIdToLocalStorage = (badgeId) => {
    const badgeIds = JSON.parse(localStorage.getItem('badgeIds')) || [];
    if (!badgeIds.includes(badgeId)) {
      badgeIds.push(badgeId);

      localStorage.setItem('badgeIds', JSON.stringify(badgeIds));
    }
  };
  const handleTaskCompletion = (badgeId) => {
    addBadgeIdToLocalStorage(badgeId);

    setBadges((prevBadges) =>
      prevBadges.map((badge) =>
        badge.id === badgeId ? { ...badge, show: true } : badge
      )
    );
  };

  const history = useHistory();

  const goToDetailsPage = (e) => {
    e.preventDefault();
    history.push("/account/all-badges")
  };

  const goToTeamDetailsPage = (e) => {
    e.preventDefault();
    history.push("/account/team-details")
  };

  const getTaskProgress = (taskName) => {
    if (!taskName) {
      return 0;
    }

    const taskInfo = allTasks.find((data) => data.name === taskName)
    if (!taskInfo) {
      return 0
    }
    const calcPer = (taskInfo.tasksCount / taskInfo.totalTasksCount) * 100

    return Math.floor(calcPer)
  }

  const capitalizeFirstAlpabate = (name) => {
    if (typeof name !== 'string' || name.length === 0) {
      return '';
    }
    let spaceTrimName = name
    return spaceTrimName.charAt(0).toUpperCase() + spaceTrimName.slice(1).toLowerCase();
  }
  return (
    <>
      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
      <div className="lg:block hidden p-2">
        <div class="flex mb-6 h-[350px]">
          <div class="relative mr-6 p-4 w-[75%] pointSystembg">
            <div class="flex">
              <div className="relative pr-4 w-1/2">
                <div class="flex justify-start items-center mt-0 p-1">
                  <div className="flex border-16 border-gray-300 bg-transparent rounded-md overflow-hidden">
                    <button
                      onClick={() => handleFilterSelect("Daily")}
                      className={`custom-font-size-16 px-6 py-1 text-center w-25 focus:bg-white focus:text-black border-right border-gray-300 text-white m-1 transition-all duration-300 ease-in-out ${selectedFilter === "Daily" ? "!bg-white !text-black  rounded-md " : "opacity-70"}`}
                    >
                      Daily
                    </button>
                    <button
                      onClick={() => handleFilterSelect("Weekly")}
                      className={`custom-font-size-16 px-6 py-1 text-center w-25 focus:bg-white focus:text-black border-right border-gray-300 text-white m-1 transition-all duration-300 ease-in-out ${selectedFilter === "Weekly" ? "!bg-white !text-black rounded-md " : "opacity-70"}`}
                    >
                      Weekly
                    </button>
                    <button
                      onClick={() => handleFilterSelect("Monthly")}
                      className={`custom-font-size-16 px-6 py-1 text-center w-25 focus:bg-white focus:text-black text-white m-1 transition-all duration-300 ease-in-out ${selectedFilter === "Monthly" ? "!bg-white !text-black  rounded-md " : "opacity-70"}`}
                    >
                      Monthly
                    </button>
                  </div>



                </div>




                {!loadedPoints ? (
                  <table className="mt-3 mb-0 min-w-full text-white">
                    <tbody>

                      {[...Array(3)].map((_, index) => (
                        <tr key={index}>
                          <td className={`p-3 ${index === 2 ? '' : 'border-b border-gray-300'}`}>
                            <div className="bg-gray-300 rounded-full w-6 h-6 animate-pulse"></div>
                          </td>
                          <td className={`p-3 ${index === 2 ? '' : 'border-b border-gray-300'}`}>
                            <div className="bg-gray-300 border rounded-full w-[55px] h-[55px] animate-pulse"></div>
                          </td>
                          <td className={`p-3 ${index === 2 ? '' : 'border-b border-gray-300'}`}>
                            <div className="bg-gray-300 rounded-md w-[120px] h-6 animate-pulse"></div>
                          </td>
                          <td className={`p-3 ${index === 2 ? '' : 'border-b border-gray-300'}`}>
                            <div className="bg-gray-300 rounded-md w-[60px] h-6 animate-pulse"></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                ) : (


                  <table className="mt-3 mb-0 min-w-full text-white">
                    <tbody>
                      {winnerPointsArr && winnerPointsArr.length > 0 ? (
                        winnerPointsArr
                          .sort((a, b) => b.totalPoints - a.totalPoints)
                          .map((user, index) => {
                            const fullName = `${user.firstName} ${user.lastName}`;
                            const profileImageUrl = user.profileImage || dummy;

                            return (
                              <tr key={user.userId} className={` ${index !== 2 ? 'border-btm' : ''}`}>
                                <td className={` p-3 ${index === 2 ? 'flex items-center' : 'flex items-center'}`} style={{ whiteSpace: 'nowrap' }}>
                                  <span className="inline-flex justify-center items-center mr-6 rounded-full w-6 h-6 custom-font-size-16 text-white number-bg">
                                    {index + 1}
                                  </span>
                                  <img
                                    src={profileImageUrl}
                                    alt="User"
                                    className="border-2 bg-gray-300 ml-2 border-red-500 rounded-full w-[50px] h-[50px]"
                                  />
                                </td>

                                <td className={`custom-font-size-16 capitalize opacity-70 p-3 ${index === 2 ? '' : ''}`} style={{ width: '35%' }}>
                                  {fullName}
                                </td>
                                <td className={` custom-font-size-16 p-3 ${index === 2 ? '' : ''}`} style={{ width: '35%' }}>
                                  {user.totalPoints}
                                </td>
                              </tr>




                            );
                          })
                      ) : (
                        null
                      )}
                    </tbody>
                  </table>

                )}




              </div>
              {loadedPoints && winnerPointsArr && winnerPointsArr.length === 0 && (
                <div className="relative h-80">
                  <div className="top-0 left-1/2 absolute flex justify-center items-center h-full transform -translate-x-1/2">
                    <div className="flex flex-col justify-center items-center opacity-70 text-center">
                      <img
                        src={nodataFound}
                        alt="No Data"
                        className="mb-4"
                        style={{ width: '100%', height: 'auto' }}
                      />
                      <h3 className="font-semibold text-[28px] text-white whitespace-nowrap">
                        No Data Found!
                      </h3>
                    </div>
                  </div>
                </div>
              )}




              {!loadedPoints ? (
                <div
                  className="relative flex flex-col justify-around items-center mt-2 pl-4 w-1/2">
                  <div
                    className="relative flex justify-between items-center gap-8 w-[100%] h-full"
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {[...Array(3)].map((_, index) => (
                      <div
                        key={index}
                        className="relative flex flex-col items-center"
                        style={{ width: '100px' }}
                      >
                        <div
                          className="relative flex justify-center items-center bg-gray-300 rounded-full animate-pulse"
                          style={{
                            height: '100px',
                            width: '100px',
                          }}
                        ></div>
                        <div className="bg-gray-300 mt-3 rounded-md w-[100px] h-6 animate-pulse"></div>
                        <div className="bg-gray-300 mt-2 rounded-md w-[60px] h-6 animate-pulse"></div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                winnerPointsArr &&
                winnerPointsArr.length > 0 && (
                  <div
                    className="relative flex flex-col justify-around items-center mt-0 pl-4 w-1/2"
                    style={{
                      backgroundImage: `url(${mask})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div
                      className="relative flex items-center gap-4 opacity-90 w-[80%] h-full"
                      style={{
                        marginTop: '8rem',
                        display: 'flex',
                        justifyContent:
                          winnerPointsArr.length === 1
                            ? 'center'
                            : winnerPointsArr.length === 2
                              ? 'space-evenly'
                              : 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {(() => {
                        // Sort users by totalPoints in descending order
                        const sortedUsers = [...winnerPointsArr].sort((a, b) => b.totalPoints - a.totalPoints);
                        // Arrange positions: highest points at center, second on left, third on right
                        const arrangedUsers = [];
                        if (sortedUsers.length >= 1) {
                          arrangedUsers.push(sortedUsers[0]); // Most points (center)
                        }
                        if (sortedUsers.length >= 2) {
                          arrangedUsers.unshift(sortedUsers[1]); // Second most points (left)
                        }
                        if (sortedUsers.length >= 3) {
                          arrangedUsers.push(sortedUsers[2]); // Third most points (right)
                        }
                        return arrangedUsers.map((user, index) => {
                          let rankKey;
                          if (user === sortedUsers[0]) rankKey = 1; // Most points
                          else if (user === sortedUsers[1]) rankKey = 2; // Second most points
                          else if (user === sortedUsers[2]) rankKey = 3; // Third most points

                          const fullName = `${user.firstName} ${user.lastName}`;
                          const profileImageUrl = user.profileImage || dummy;

                          // Assign different styles based on rank
                          let backgroundColor, borderColor, height;
                          if (rankKey === 1) {
                            backgroundColor = '#D83307';
                            borderColor = '#D83307';
                            height = '120px';
                          } else if (rankKey === 2) {
                            backgroundColor = '#707070';
                            borderColor = '#707070';
                            height = '85px';
                          } else if (rankKey === 3) {
                            backgroundColor = '#0050AE';
                            borderColor = '#0050AE';
                            height = '80px';
                          } else {
                            backgroundColor = '#3D97FF';
                            borderColor = '#3D97FF';
                            height = '80px';
                          }

                          return (
                            <div
                              key={user.userId}
                              className="relative flex flex-col items-center"
                              style={{
                                width: '100px',
                                order: rankKey === 2 ? 0 : rankKey === 3 ? 2 : 1, // Order for left, center, right
                              }}
                            >
                              {rankKey === 1 && (
                                <img
                                  src={crown}
                                  alt="Crown"
                                  className="top-[-30px] absolute w-10 h-7"
                                />
                              )}

                              <div
                                className="relative flex justify-center items-center rounded-full"
                                style={{
                                  height: height,
                                  width: height,
                                  border: `3px solid ${borderColor}`,
                                }}
                              >
                                {rankKey === 1 && (
                                  <Player
                                    src={lightFile}
                                    background="transparent"
                                    speed={1}
                                    className="top-[-55px] left-[-55px] z-0 absolute w-[220px] h-[220px]"
                                    loop
                                    autoplay
                                  />
                                )}
                                <img
                                  src={profileImageUrl}
                                  alt="Profile Image"
                                  className="z-10 absolute bg-gray-100 rounded-full w-full h-full object-cover"
                                />
                                <div
                                  className="bottom-[-10px] absolute flex justify-center items-center rounded-full w-5 h-5 text-white"
                                  style={{
                                    backgroundColor: backgroundColor,
                                    zIndex: 10,
                                  }}
                                >
                                  <span className="font-normal text-[12px]">{rankKey}</span>
                                </div>
                              </div>
                              <p
                                className="opacity-70 mt-3 mb-2 custom-font-size-16 text-sm text-white truncate capitalize"
                                style={{ maxWidth: '100px' }}
                              >
                                {fullName}
                              </p>
                              <p
                                className="custom-font-size-16 text-white text-xl truncate"
                                style={{ maxWidth: '100px' }}
                              >
                                {user.totalPoints}
                              </p>
                            </div>
                          );
                        });
                      })()}
                    </div>
                  </div>
                )


              )}
            </div>
          </div>


          <div class="p-4 w-[25%] h-75 titleContainerbg">
            <div class="flex justify-between items-center border-gray-300 mb-4 pb-4 border-btm">
              <div class="flex items-center space-x-2">
                <div class="font-semibold text-lg">Your Badges</div>
                <span class="text-gray-600">
                  <img src={vector} alt="Badge 1" class="rounded-full w-4 h-4" />
                </span>
              </div>
              <button class="border-none font-bold text-gray-800 underline" onClick={goToDetailsPage}>
                View All
              </button>
            </div>


            <div className="relative flex flex-wrap items-center gap-5">
              {!loaded ? (
                [...Array(12)].map((_, index) => (
                  <div
                    key={index}
                    className="relative flex justify-center items-center rounded-full w-12 h-12 animate-pulse badge group"
                  >
                    <div
                      className="bg-gray-300 rounded-full w-12 h-12"
                      style={{
                        animation: "pulse 1.5s infinite ease-in-out",
                      }}
                    ></div>
                
                  </div>
                ))
              ) : (
                badges
                  .sort((a, b) => (b.show ? 1 : 0) - (a.show ? 1 : 0))
                  .map((badge, index) => (
                    <div
                      key={badge.id}
                      className="relative flex justify-center items-center rounded-full w-12 h-12 transform transition-all duration-300 badge ease-in-out group hover:scale-105"
                    >
                      <img
                        src={badge.show ? badge.src : inactivebadge}
                        alt={badge.alt}
                        className={`z-10 rounded-full w-14 h-14 transition-all duration-300 object-cover ${!badge.show ? 'opacity-50' : ''}`}
                      />

                      {/* <div
                        className="bottom-[-25px] left-1/2 z-20 absolute border-2 bg-gray-100 opacity-0 group-hover:opacity-100 px-2 py-1 rounded text-black text-xs transform transition-opacity -translate-x-1/2 duration-300"
                      >
                        {badge.alt}
                      </div> */}
                      <div className="absolute inset-0 rounded-full"></div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-6 mb-12">


          <div
            className={`flex flex-col p-0 ${userTeamInfo?.name ? 'w-[30%]' : 'w-[50%]'
              }`}
          >
            {!loadedUserPoints ? (
              <div className="flex flex-col mb-6 streakContainerBg" style={{ height: '400px' }}>

                <div className="flex p-4 w-full">
                  <div className="flex items-start w-full">
                    <div className="flex-shrink-0">
                      <div className="bg-gray-300 mx-auto rounded-full w-20 h-20 animate-pulse"></div>
                    </div>
                    <div className="flex-1 ml-3">
                      <div className="bg-gray-300 mb-2 rounded w-32 h-6 animate-pulse"></div>
                      <div className="bg-gray-300 rounded w-48 h-4 animate-pulse"></div>
                    </div>
                  </div>
                </div>

                {/* Skeleton for Streak Target Section */}
                <div className="flex justify-between items-center mx-auto p-4 rounded-md w-full streakBorder" style={{ borderRadius: '6px', width: '95%' }}>
                  <div className="bg-gray-300 mb-2 rounded w-32 h-6 animate-pulse"></div>
                  <div className="bg-gray-300 w-48 h-4 animate-pulse"></div>
                </div>

                {/* Skeleton for Days of the Week Section */}
                <div className="mt-0 p-4">
                  <div className="flex flex-wrap bg-white p-4 rounded-lg w-full" style={{ borderRadius: '6px' }}>
                    {[...Array(7)].map((_, index) => (
                      <div key={index} className="flex flex-col mb-2 basis-1/4">
                        <div className="bg-gray-300 mb-2 rounded-full w-10 h-10 animate-pulse"></div>
                        <div className="bg-gray-300 rounded-full w-10 h-4 animate-pulse"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col mb-6 streakContainerBg" style={{ height: '400px' }}>
                {/* Badge and Streak Info */}
                <div className="flex p-4 w-full">
                  <div className="flex items-start w-full">
                    <div className="flex-shrink-0">
                      <img
                        src={startbadge}
                        alt="Badge Image"
                        className="mx-auto rounded-full w-20 h-20"
                      />
                    </div>
                    <div className="flex-1 ml-3">
                      <h3 className="font-semibold text-lg streakTitle">
                        {loginStreakArr?.length}{" "}
                        {loginStreakArr?.length === 1 || loginStreakArr?.length === 0
                          ? "day"
                          : "days"}{" "}
                        streak
                      </h3>
                      <p className="mt-1 streakPara">
                        Play daily, keep streak, earn extra points upon reaching targets!
                      </p>
                    </div>
                  </div>
                </div>

                {/* Streak Target Section */}
                <div className="flex justify-between items-center mx-auto p-4 rounded-md w-full streakBorder" style={{ borderRadius: '18px', width: '94%' }}>
                  <h3 className="streakTitle">Streak Target</h3>
                  <div className="inline-block bg-blue-500 px-2 py-2 rounded-md text-md text-white">
                    Reach 7 days streak to earn 500 points
                  </div>
                </div>

                {/* Days of Week Section */}
                <div className="mt-0 p-4">
                  <div className="flex flex-wrap bg-white p-4 rounded-lg w-full" style={{ borderRadius: '18px' }}>
                    {daysOfWeek.map((day, index) => {
                      const dayDate = currentWeekDates[index];
                      const isLoginDay = loginStreakArr?.includes(dayDate);
                      const starSrc = isLoginDay && userInfo ? starempty : starfilled;

                      return (
                        <div key={day} className="flex flex-col mb-2 basis-1/4">
                          <span
                            className={`text-md font-semibold streakTaskTitle ${isLoginDay ? "text-blue-500" : ""}`}
                          >
                            {day}
                          </span>
                          <div className="flex justify-center items-center mt-1 rounded-full w-10 h-10">
                            <img
                              src={starSrc}
                              alt="star"
                              className="rounded-full w-10 h-10"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>


          <div
            className={`flex flex-col p-0 ${userTeamInfo?.name ? 'w-[40%]' : 'w-[50%]'
              }`}
          >
            <div className="p-4 streakYellowBg" style={{ height: '400px' }}>
              <div className="flex justify-between items-center pb-3" >
                <div className="flex items-center">
                  <img src={target} alt="icon" className="mr-2 w-8 h-10" />
                  <h3 className="font-semibold text-lg">Tasks</h3>
                  
                </div>
              </div>

              {/* Filter Buttons Section */}
              <div className="inline-block relative mt-0 mb-2">
                <div className="flex justify-start items-center p-1">
                  <div className="flex border-16 bg-transparent opacity-70 border-black rounded-md overflow-hidden">
                    <button
                      onClick={() => handleFilterSelectTask("Daily")}
                      className={`custom-font-size-16 px-6 py-1 text-center w-25 focus:text-black border-right-black border-gray-300 m-1 transition-all duration-300 ease-in-out ${selectedFilterTask === "Daily" ? "!bg-black !text-white rounded-md" : ""
                        }`}
                    >
                      Daily
                    </button>
                    <button
                      onClick={() => handleFilterSelectTask("Weekly")}
                      className={`custom-font-size-16 px-6 py-1 text-center w-25 focus:text-black border-right-black border-gray-300 m-1 transition-all duration-300 ease-in-out ${selectedFilterTask === "Weekly" ? "!bg-black !text-white rounded-md" : ""
                        }`}
                    >
                      Weekly
                    </button>
                    <button
                      onClick={() => handleFilterSelectTask("Monthly")}
                      className={`custom-font-size-16 px-6 py-1 text-center w-25 focus:text-black m-1 transition-all duration-300 ease-in-out ${selectedFilterTask === "Monthly" ? "!bg-black !text-white rounded-md" : ""
                        }`}
                    >
                      Monthly
                    </button>
                  </div>
                </div>
              </div>
              <div className='taskScroll'>
                {loadedTask ? (
                  allTasks && userTasks[selectedFilterTask] && userTasks[selectedFilterTask].map((task, index) => {
                    const progressWidth = allTasks.some((data) => data.name === task?.taskName) ? getTaskProgress(task?.taskName) : 0;

                    return (
                      <div className="mt-4 mb-4 p-4 pt-0 pb-0 pl-0" key={index}>
                        <h4 className="mb-0 font-semibold streakTaskTitle">{task?.taskName ? `${task?.taskName}: ${task?.points} points` : ''}</h4>
                        <div className="relative pt-1">
                          <div className="flex mb-0">
                            <div
                              className="border rounded-full w-full"
                              style={{ height: '16px', border: '1px solid #80600033', position: 'relative' }} // Ensure position is relative for absolute child
                            >
                              <div
                                className="rounded-full streakProgress"
                                style={{
                                  width: `${progressWidth}%`,
                                  height: '16px',
                                  backgroundColor: '#4caf50',
                                }}
                              />
                              {/* {progressWidth > 0 && ( */}
                              <img
                                src={progressImage1}
                                alt="progress end"
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: userTeamInfo?.name ? '90%' : '95%',
                                  transform: 'translateY(-50%)',
                                  height: '40px',
                                  width: 'auto',
                                }}
                              />
                              {/* )} */}
                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  })
                ) : (

                  <div>
                    {[...Array(3)].map((_, index) => (
                      <div className="mt-4 mb-4 p-4 pt-0 pb-0 pl-0" key={index}>
                        <div className="bg-gray-300 mb-2 rounded w-48 h-6 animate-pulse"></div>
                        <div className="relative pt-1">
                          <div className="bg-gray-300 rounded-full w-full animate-pulse" style={{ height: '16px' }}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

          </div>

          {
            userTeamInfo && userTeamInfo?.name && (
              <div className="flex flex-col p-0 w-[30%]">
                <div className="p-4 whitebgwithborder" style={{ height: '400px' }}>
                  <div className="flex justify-between items-center">

                    <h2 class="font-semibold text-[#23282E] text-lg">{userInfo && userInfo?.data && userInfo?.data?.firstName ? capitalizeFirstAlpabate(userInfo?.data?.firstName) : ''} -  <span class="highlight">{userTeamInfo ? capitalizeFirstAlpabate(userTeamInfo?.name) : ''}</span></h2>
                    <button onClick={goToTeamDetailsPage} className="border-[#23282E] border-b-[1px] font-semibold leading-[15px]">
                      View All
                    </button>

                  </div>

                  <div className="flex items-center mt-1">
                    <img src={coin} alt="Logo" className="mr-3 w-10 h-10" />
                    <h3 className="text-[#23282E] streakTitle">{userTeamInfo && userTeamInfo?.totalTeamsPoints ? userTeamInfo?.totalTeamsPoints : 0} Score</h3>
                  </div>

                  <div className="mt-3 p-2 teamListScroll" style={{ backgroundColor: '#E1F2FC80', borderRadius: '10px' }}>
                    <table className="border-[#23282] border-b-2 rounded-lg min-w-full" >
                      <tbody>
                        {
                          teamsReports && teamsReports.length > 0 && teamsReports.map((team, index) => (
                            <tr className="border-b">
                              <td className="p-4 font-semibold text-[#23282E]">{index + 1}</td>
                              <td className="p-4">
                                <img src={team?.teamLogo ? S3_BASE_URL + team?.teamLogo : target} alt="Example" className="rounded-full w-12 h-12" />
                              </td>
                              <td className="p-4 font-semibold text-[#23282E] streakTaskTitle">{team?.name ? capitalizeFirstAlpabate(team?.name) : ''}</td>
                              <td className="p-4 font-semibold text-[#23282E]">{team?.totalTeamsPoints ? team?.totalTeamsPoints : 0} score</td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          }



        </div>






      </div>
      <div className="block md:hidden">

        <div className="block md:hidden mt-3">
          <div className="flex flex-wrap bg-[#FDF1F3] rounded-lg w-full overflow-hidden">
            <button
              onClick={() => handleFilterSelect("Daily")}
              className={`custom-font-size-16 px-6 py-2 text-center flex-1 md:w-1/3 focus:text-black border-black m-1 transition-all duration-300 ease-in-out ${selectedFilter === "Daily" ? "!bg-white !text-black  rounded-lg" : ""}`}
            >
              Daily
            </button>
            <button
              onClick={() => handleFilterSelect("Weekly")}
              className={`custom-font-size-16 px-6 py-2 text-center flex-1 md:w-1/3 focus:text-black border-gray-300 m-1 transition-all duration-300 ease-in-out ${selectedFilter === "Weekly" ? "!bg-white !text-black  rounded-lg" : ""}`}
            >
              Weekly
            </button>
            <button
              onClick={() => handleFilterSelect("Monthly")}
              className={`custom-font-size-16 px-6 py-2 text-center flex-1 md:w-1/3 focus:text-black m-1 transition-all duration-300 ease-in-out ${selectedFilter === "Monthly" ? "!bg-white !text-black rounded-lg" : ""}`}
            >
              Monthly
            </button>
          </div>






          <div className="mx-auto mt-6 p-2 max-w-4xl pointSystembg">
            {loadedPoints && winnerPointsArr && winnerPointsArr.length === 0 && (
              <div className="relative h-80">
                <div className="top-0 left-1/2 absolute flex justify-center items-center h-full transform -translate-x-1/2">
                  <div className="flex flex-col justify-center items-center opacity-70 text-center">
                    <img
                      src={nodataFound}
                      alt="No Data"
                      className="mb-4"
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <h3 className="font-semibold text-[34px] text-white whitespace-nowrap">
                      No Data Found!
                    </h3>
                  </div>
                </div>
              </div>
            )}


            {!loadedPoints ? (

              <div className="relative flex flex-col justify-around items-center mt-6 pl-0 w-full">
                <div className="relative flex justify-between items-center gap-6 w-[100%] h-full">
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className="relative flex flex-col items-center w-[100px]">
                      <div className="bg-gray-300 mb-4 rounded-full w-10 h-10 animate-pulse"></div>
                      <div className="bg-gray-300 mb-2 w-[60px] h-[20px] animate-pulse"></div>
                      <div className="bg-gray-300 w-[60px] h-[20px] animate-pulse"></div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              winnerPointsArr &&
              winnerPointsArr.length > 0 && (
                <div
                  className="relative flex flex-col justify-around items-center mt-0 pl-0 w-full"
                  style={{
                    backgroundImage: `url(${mask})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div
                    className="relative flex items-center gap-4 opacity-90 w-full h-full"
                    style={{
                      marginTop: '6rem',
                      display: 'flex',
                      justifyContent:
                        winnerPointsArr.length === 1
                          ? 'center'
                          : winnerPointsArr.length === 2
                            ? 'space-evenly'
                            : 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {(() => {
                      // Sort users by totalPoints in descending order
                      const sortedUsers = [...winnerPointsArr].sort((a, b) => b.totalPoints - a.totalPoints);

                      // Arrange positions: highest points at center, second on left, third on right
                      const arrangedUsers = [];
                      if (sortedUsers.length >= 1) {
                        arrangedUsers.push(sortedUsers[0]); // Most points (center)
                      }
                      if (sortedUsers.length >= 2) {
                        arrangedUsers.unshift(sortedUsers[1]); // Second most points (left)
                      }
                      if (sortedUsers.length >= 3) {
                        arrangedUsers.push(sortedUsers[2]); // Third most points (right)
                      }

                      return arrangedUsers.map((user, index) => {
                        let rankKey;
                        if (user === sortedUsers[0]) rankKey = 1; // Most points
                        else if (user === sortedUsers[1]) rankKey = 2; // Second most points
                        else if (user === sortedUsers[2]) rankKey = 3; // Third most points

                        const fullName = `${user.firstName} ${user.lastName}`;
                        const profileImageUrl = user.profileImage || dummy;

                        // Assign different styles based on rank
                        let backgroundColor, borderColor, height;
                        if (rankKey === 1) {
                          backgroundColor = '#D83307';
                          borderColor = '#D83307';
                          height = '110px';
                        } else if (rankKey === 2) {
                          backgroundColor = '#707070';
                          borderColor = '#707070';
                          height = '70px';
                        } else if (rankKey === 3) {
                          backgroundColor = '#0050AE';
                          borderColor = '#0050AE';
                          height = '70px';
                        } else {
                          backgroundColor = '#3D97FF';
                          borderColor = '#3D97FF';
                          height = '70px';
                        }

                        return (
                          <div
                            key={user.userId}
                            className="relative flex flex-col items-center"
                            style={{
                              width: '110px',
                              order: rankKey === 2 ? 0 : rankKey === 3 ? 2 : 1, // Order for left, center, right
                            }}
                          >
                            {rankKey === 1 && (
                              <img
                                src={crown}
                                alt="Crown"
                                className="top-[-30px] absolute w-8 h-7"
                              />
                            )}

                            <div
                              className="relative flex flex-col items-center"
                              style={{
                                height: height,
                                width: height,
                              }}
                            >
                              {rankKey === 1 && (
                                <Player
                                  src={lightFile}
                                  background="transparent"
                                  speed={1}
                                  className="top-[-55px] left-[-55px] z-0 absolute w-[220px] h-[220px]"
                                  loop
                                  autoplay
                                />
                              )}

                              {/* Profile Image with Border */}
                              <div
                                className="absolute flex justify-center items-center bg-gray-200 rounded-full"
                                style={{
                                  height: height,
                                  width: height,
                                  border: `3px solid ${rankKey === 1 ? '#D83307' : rankKey === 2 ? '#707070' : '#0050AE'}`,
                                }}
                              >
                                <img
                                  src={profileImageUrl}
                                  alt="Profile Image"
                                  className="z-10 rounded-full w-full h-full object-cover"
                                />
                              </div>

                              {/* Rank Circle */}
                              <div
                                className="bottom-[-10px] absolute flex justify-center items-center rounded-full text-white"
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  backgroundColor: rankKey === 1 ? '#D83307' : rankKey === 2 ? '#707070' : '#0050AE',
                                  border: `3px solid ${borderColor}`,
                                  zIndex: 20, // Ensure this is above the profile image and other elements
                                }}
                              >
                                <span className="font-normal text-[12px]">{rankKey}</span>
                              </div>
                            </div>

                            <p
                              className="opacity-70 mt-3 mb-2 text-sm text-white truncate capitalize"
                              style={{ maxWidth: '100px' }}
                            >
                              {fullName}
                            </p>
                            <p
                              className="text-white text-xl truncate"
                              style={{ maxWidth: '100px' }}
                            >
                              {user.totalPoints}
                            </p>
                          </div>


                        );
                      });
                    })()}
                  </div>
                </div>
              )

            )}

            <div className="overflow-x-auto">
              <table className="mt-3 mb-0 min-w-full text-white">
                <tbody>
                  {!loadedPoints ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index} className="animate-pulse">
                        <td className="p-2 border-b">
                          <div className="bg-gray-300 rounded-full w-[55px] h-[55px]"></div>
                        </td>
                        <td className="p-2 border-b">
                          <div className="bg-gray-300 w-[100px] h-[20px]"></div>
                        </td>
                        <td className="p-2 border-b">
                          <div className="bg-gray-300 w-[60px] h-[20px]"></div>
                        </td>
                      </tr>
                    ))
                  ) : winnerPointsArr && winnerPointsArr.length > 0 ? (
                    winnerPointsArr
                      .sort((a, b) => b.totalPoints - a.totalPoints)
                      .map((user, index) => {
                        const fullName = `${user.firstName} ${user.lastName}`;
                        const profileImageUrl = user.profileImage || dummy;

                        return (
                          <tr key={user.userId}>
                            <td
                              className={`p-2 py-6 ${index === 2 ? '' : 'border-btm'}`}
                              style={{ width: '70%' }}
                            >
                              <div className="flex items-center space-x-4">
                                <span
                                  className="flex items-center justify-center bg-blue-400 text-white rounded-full w-8 h-8"
                                >
                                  {index + 1}
                                </span>

                                <img
                                  src={profileImageUrl}
                                  alt="User"
                                  className="border-2 bg-gray-300 border-red-500 rounded-full w-[50px] h-[50px]"
                                />
                                <div className="opacity-70 truncate capitalize">{fullName}</div>
                              </div>
                            </td>


                            <td className={`p-2 py-6 ${index === 2 ? '' : 'border-btm'}`} style={{ width: '30%' }}>
                              {user.totalPoints}
                            </td>
                          </tr>

                        );
                      })
                  ) : null}
                </tbody>
              </table>
            </div>

          </div>

          <div className="mt-6">
            <div className="p-0 w-full h-75 ">
              <div className="flex justify-between items-center border-gray-300 mb-4 pb-0 ">
                <div className="flex items-center space-x-2">
                  <div className="font-semibold text-lg">Your Badges</div>
                  {/* <span className="text-gray-600">
          <img src={vector} alt="Badge 1" className="rounded-full w-4 h-4" />
        </span> */}
                </div>
                <button
                  className="border-[#D83307] border-b-[1px] text-[#D83307]"
                  onClick={goToDetailsPage}
                >
                  View All
                </button>
              </div>

              <div className="relative grid grid-cols-4 gap-4 justify-items-center items-center titleContainerbgMob">
                {!loaded ? (
                  [...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="relative flex justify-center items-center rounded-full w-16 h-16 animate-pulse badge group"
                    >
                      <div
                        className="bg-gray-300 rounded-full w-14 h-14"
                        style={{
                          animation: "pulse 1.5s infinite ease-in-out",
                        }}
                      ></div>
                    </div>
                  ))
                ) : (
                  badges
                    .sort((a, b) => (b.show ? 1 : 0) - (a.show ? 1 : 0))
                    .slice(0, 4) // Show only the first 4 badges
                    .map((badge, index) => (
                      <div
                        key={badge.id}
                        className="relative flex justify-center items-center rounded-full w-19 h-19 transform transition-all duration-300 badge ease-in-out group hover:scale-105"
                      >
                        <img
                          src={badge.show ? badge.src : inactivebadge}
                          alt={badge.alt}
                          className={`z-10 rounded-md w-full h-full transition-all duration-300 object-cover bg-white p-2 ${!badge.show ? 'opacity-50' : ''
                            }`}
                        />
                        <div
                          className="bottom-[-25px] left-1/2 z-20 absolute border-2 bg-gray-100 opacity-0 group-hover:opacity-100  rounded text-black text-xs transform transition-all -translate-x-1/2 translate-y-[-10px] group-hover:translate-y-0 duration-300"
                        >
                          {badge.alt}
                        </div>
                        <div className="absolute inset-0 rounded-full"></div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>


          <div className="mt-6">
            <div className="flex flex-col mx-auto p-0 w-full sm:w-[60%]">
              <div className="flex sm:flex-row flex-col mb-6">
                <div className="flex flex-col p-0 sm:w-1/2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 px-0 py-0 rounded-full text-white">
                      {!loaded ? (
                        <div className="bg-gray-300 rounded-full w-24 h-24 animate-pulse" />
                      ) : (
                        <img src={startbadge} alt="Badge Image" className="mx-auto rounded-full w-24 h-24" />
                      )}
                    </div>

                    <div className="flex-1 mt-3 sm:mt-0 ml-3">

                      {!loaded ? (
                        <div className="bg-gray-300 rounded-lg w-[120px] h-5 animate-pulse"></div>
                      ) : (
                        <h3 className="streakTitle">
                          {loginStreakArr?.length} {loginStreakArr?.length === 1 || loginStreakArr?.length === 0 ? 'day' : 'days'} streak
                        </h3>
                      )}


                      {!loaded ? (
                        <div className="bg-gray-300 mt-2 rounded-lg w-[200px] h-4 animate-pulse"></div>
                      ) : (
                        <p className="mt-2 streakPara">Play daily, Keep streak, earn extra points upon reaching targets!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-lg streakContainerBg">
                <div className="mb-3 p-2">
                  {!loaded ? (
                    <div className="bg-gray-300 mt-3 rounded-lg w-[150px] h-6 animate-pulse"></div>
                  ) : (
                    <h3 className="text-lg streakTitle">Streak Target</h3>
                  )}
                  {!loaded ? (
                    <div className="inline-block bg-gray-300 mt-3 p-2 rounded-lg w-[250px] h-4 text-white animate-pulse streakdiv">
                    </div>
                  ) : (
                    <div className="inline-block mt-3 p-2 text-white streakdiv">
                      Reach 7 days streak to earn 500 points
                    </div>
                  )}
                </div>
                <div className="bg-white m-1 mb-2 rounded-lg">
                  <div
                    className="grid grid-cols-4 gap-2 bg-white mx-auto p-0 rounded-xl"
                    style={{ width: "100%" }}
                  >
                    {!loadedUserPoints ? (
                      [...Array(7)].map((_, index) => (
                        <div className="flex flex-col items-center mt-2 mb-2 p-0" key={index}>
                          <div className="bg-gray-300 rounded-full w-12 h-12 animate-pulse"></div>
                        </div>
                      ))
                    ) : (
                      daysOfWeek.map((day, index) => {
                        const dayDate = currentWeekDates[index];
                        const isLoginDay = loginStreakArr?.includes(dayDate);
                        const starImage = isLoginDay && userInfo ? "starfilled" : "starempty";
                        const starSrc = starImage === "starfilled" ? starempty : starfilled;

                        return (
                          <div
                            className="flex flex-col items-center mt-2 mb-2 p-0 "
                            key={day}
                          >
                            <span
                              className={`font-semibold text-sm streakTaskTitle ${isLoginDay ? "text-[#65A4EE]" : ""
                                }`}
                            >
                              {day} {/* Show the full day name */}
                            </span>
                            <div className="flex justify-center items-center mt-1 p-0 rounded-full w-10 h-10">
                              <img
                                src={starSrc}
                                alt="star"
                                className="mx-auto rounded-full w-10 h-10"
                              />
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="mt-6 mb-3">
            <div className="p-4 streakYellowBg">
              {/* Header Section */}
              <div className="flex items-center">
                <img src={target} alt="icon" className="mr-2 w-6 h-7" />
                <h3 className="font-semibold text-lg">Tasks {userTeamInfo?.name}</h3>
              </div>

              {/* Buttons Section with Border Below */}
              <div className="mt-4 pb-3" style={{ borderBottom: '2px solid #80600033' }}>
                <div className="inline-block relative">
                  <div className="flex justify-evenly border-16 bg-transparent opacity-70 border-black rounded-md overflow-hidden">
                    <button
                      onClick={() => handleFilterSelectTask("Daily")}
                      className={`custom-font-size-16 px-4 py-1 text-center focus:text-black m-1 transition-all duration-300 ease-in-out ${selectedFilterTask === "Daily" ? "!bg-black !text-white rounded-md" : ""
                        }`}
                    >
                      Daily
                    </button>
                    <hr className="border-r-2 border-black h-10" />
                    <button
                      onClick={() => handleFilterSelectTask("Weekly")}
                      className={`custom-font-size-16 px-4 py-1 text-center focus:text-black m-1 transition-all duration-300 ease-in-out ${selectedFilterTask === "Weekly" ? "!bg-black !text-white rounded-md" : ""
                        }`}
                    >
                      Weekly
                    </button>
                    <hr className="border-r-2 border-black h-10" />
                    <button
                      onClick={() => handleFilterSelectTask("Monthly")}
                      className={`custom-font-size-16 px-4 py-1 text-center focus:text-black m-1 transition-all duration-300 ease-in-out ${selectedFilterTask === "Monthly" ? "!bg-black !text-white rounded-md" : ""
                        }`}
                    >
                      Monthly
                    </button>
                  </div>
                </div>
              </div>

              {/* Task List Section */}
              <div className="mt-2 taskScroll">
                {!loadedTask ? (
                  // Skeleton Loader
                  [...Array(3)].map((_, index) => (
                    <div className="mb-6" key={index}>
                      <div className="bg-gray-300 mb-2 rounded-md w-40 h-6 animate-pulse"></div>
                      <div className="relative pt-1">
                        <div
                          className="border rounded-full w-full"
                          style={{ height: '16px', border: '1px solid #80600033' }}
                        >
                          <div
                            className="bg-gray-300 rounded-full animate-pulse"
                            style={{
                              width: '100%',
                              height: '16px',
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  allTasks && userTasks[selectedFilterTask] && userTasks[selectedFilterTask].map((task, index) => {
                    const progressWidth = allTasks.some((data) => data.name === task?.taskName) ? getTaskProgress(task?.taskName) : 0;
                    return (
                      <div className="mt-4 mb-4 pt-0 pb-0 pl-0" key={index}>
                        <h4 className="mb-0 font-semibold streakTaskTitle">{task?.taskName ? `${task?.taskName}: ${task?.points} points` : ''}</h4>
                        <div className="relative pt-1">
                          <div className="flex mb-0">
                            <div
                              className="border rounded-full w-[95%]"
                              style={{ height: '16px', border: '1px solid #80600033', position: 'relative' }}
                            >
                              <div
                                className="rounded-full streakProgress"
                                style={{
                                  width: `${progressWidth}%`,
                                  height: '16px',
                                  backgroundColor: '#4caf50',
                                }}
                              />
                              {/* {progressWidth > 0 && ( */}
                              <img
                                src={progressImage1}
                                alt="progress end"
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: `90%`,
                                  transform: 'translateY(-50%)',
                                  height: '40px',
                                  width: 'auto',
                                }}
                              />
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>

          {
            userTeamInfo && userTeamInfo?.name && (
              <div className="mt-6 mb-3">
                <div className="p-4 whitebgwithborder" style={{ height: '400px' }}>
                  <div className="flex justify-between items-center">

                    <h2 class="font-semibold text-[#23282E] text-lg">{userInfo && userInfo?.data && userInfo?.data?.firstName ? capitalizeFirstAlpabate(userInfo?.data?.firstName) : ''}  -  <span class="highlight">{userTeamInfo ? capitalizeFirstAlpabate(userTeamInfo?.name) : ''}</span></h2>
                    <button onClick={goToTeamDetailsPage} className="border-[#23282E] border-b-[1px] font-semibold leading-[15px]">
                      View All
                    </button>

                  </div>

                  <div className="flex items-center mt-1">
                    <img src={coin} alt="Logo" className="mr-3 w-10 h-10" />
                    <h3 className="text-[#23282E] streakTitle">{userTeamInfo && userTeamInfo?.totalMembersPoints ? userTeamInfo?.totalMembersPoints : 0} score</h3>
                  </div>

                  <div className="mt-3 p-2 teamListScroll" style={{ backgroundColor: '#E1F2FC80', borderRadius: '10px' }}>
                    <table className="border-[#23282] border-b-2 rounded-lg min-w-full" >
                      <tbody>
                        {
                          teamsReports && teamsReports.length > 0 && teamsReports.map((team, index) => (
                            <tr className="border-b">
                              <td className="p-4 font-semibold text-[#23282E]">{index + 1}</td>
                              <td className="p-4">
                                <img src={team?.teamLogo ? S3_BASE_URL + team?.teamLogo : target} alt="Example" className="rounded-full w-8 h-8" />
                              </td>
                              <td className="p-4 font-semibold text-[#23282E] streakTaskTitle">{team?.title ? capitalizeFirstAlpabate(team?.title) : ''}</td>
                              <td className="p-4 font-semibold text-[#23282E]">{team?.totalMembersPoints ? team?.totalMembersPoints : 0} score</td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            )
          }




        </div>

      </div>
    </>
  );
};

export default PointSystem;