import React, { useEffect, useState } from 'react';

import './account.css';
import '../gametitledetails/gametitledetails.css';
import '../customedesigntab/customedesigntab.css';
// image
import remove from '../../assets/images/remove.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, update, logOut, getUserPointsInfo } from '../../redux/actions/userAction';
import { changeActingOwner, uploadFile } from '../../redux/actions/commonActions';
import { Link } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getOrganisation } from '../../redux/actions/plansApiActions';
import ExtraMilePlay from '../../assets/images/ExtramilPlay.png';
import myDetail from "../../assets/backgrounds/My Details.png"
import ROLES, { OrgRoles } from '../../helpers/userTypes';
import { getAllOrganisations, leaveOrganisation, updateOrganisation } from '../../redux/actions/organisationActions';
import SaveAccountChanges from '../modal/saveAccountChanges';
import * as ActionTypes from "../../redux/constants/commonApiConstants";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { axiosApiInstance, BASE_URL, failureAlert, S3_BASE_URL, successAlert } from '../../helpers/helper';
import LoadingComponent from '../loader/LoadingComponent';
import Select from "react-select";
import { COLOR_OPTIONS } from '../../helpers/colorsoptions';

import blueBg from "../../assets/myDetail/blue-user-points.jpg"

import { Player } from "@lottiefiles/react-lottie-player";
import lightFile from "./light-animation.json"
import profileStar from "../../assets/myDetail/Profile reward.png"
import completeStars from "../../assets/myDetail/Reward.png";
import mobileStars from "../../assets/myDetail/mobile-Reward.png"
import inCompleteStars from "../../assets/myDetail/Incompleted rewards.png";
import mobileInCompleteStar from "../../assets/myDetail/mobile-incomplete-Reward.png"

import confettiImg from "../../assets/myDetail/COnfetti.png"

import completeTickMark from "../../assets/myDetail/icon task completed.png";
import InCompleteTick from "../../assets/myDetail/icon task incomplete.png";
import crateImg from "../../assets/myDetail/image 400.png";
import tabConfetti from "../../assets/myDetail/tab-confetti.png"

import { FiUpload } from "react-icons/fi";
import ConfirmModal from '../modal/confirmModal';
import { FaRegEdit } from "react-icons/fa";
import EditUserDetailModal from '../modal/editUserDetailModal';
import DefaultUser from "../../assets/myDetail/default-user.png"
import { Helmet } from 'react-helmet-async';
import UploadProfileAvatarModel from '../modal/uploadProfileAvatarModal';




const Account = (props) => {
  const UserInfo = useSelector(state => state.getUser)
  const { userInfo } = UserInfo;
  const OrgDetailsByEmail = useSelector(state => state.getOrganisation);
  const { orgDetailsByEmail } = OrgDetailsByEmail;
  const fileUpload = useSelector(state => state.uploadedFile);
  const { uploadedFile } = fileUpload;
  const userUpdate = useSelector(state => state.userUpdate);

  const UserPointsInfo = useSelector(state => state.getUserPointsInfo);
  const { userPointsInfo } = UserPointsInfo

  const [loaded, setLoaded] = useState(true);
  const [profileImg, setProfileImg] = useState('');
  const [isImgUpload, setIsImgUpload] = useState(false)
  const [userPoinstData, setUserPointsData] = useState([])
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [details, setDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  })
  const [isOpenUploadAvatarModel, setIsOpenUploadAvatarModel] = useState(false);


  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      (userUpdate && userUpdate.loading) ||
      (fileUpload && fileUpload.loading) ||
      (UserInfo && UserInfo.loading) ||
      (UserPointsInfo && UserPointsInfo.loading)
    )
      setLoaded(false)
    else {
      setLoaded(true);
    }
  }, [userUpdate, fileUpload, UserInfo, UserPointsInfo]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo && userInfo?.data) {
      setDetails((preState) => ({
        ...preState,
        email: userInfo?.data.email,
        firstName: userInfo?.data.firstName,
        lastName: userInfo?.data.lastName,
        phoneNumber: userInfo?.data.phoneNumber,

      }))
      setProfileImg(userInfo?.data?.profileImg)
      if (!userPointsInfo && !userPointsInfo?.data.length) {
        dispatch(getUserPointsInfo())
      }

    }
    else {
      dispatch(getUser())
    }
  }, [userInfo])

  useEffect(() => {
    if (userPointsInfo && userPointsInfo?.data) {
      setUserPointsData(userPointsInfo?.data)
    }
  }, [UserPointsInfo])


  // const handleUploadImg = async (e) => {
  //   e.preventDefault()
  //   if (profileImg && userInfo && userInfo?.data && userInfo?.data?.email) {

  //     const responsecode = await dispatch(update(userInfo?.data?.email, { profileImg }))
  //     if (responsecode === 200) {
  //       successAlert("Profile image updated")
  //       setIsImgUpload(false)
  //       dispatch(getUser())
  //       dispatch(getUserPointsInfo())
  //     }

  //   }
  // }


  const handleUserProfileImg = (e) => {
    e.persist();
    const file = e.target.files[0];

    // Reset the input value
    e.target.value = null;

    // Check if file exists
    if (!file) return;

    // Check file size (1MB = 1024 * 1024 bytes)
    if (file.size > 1024 * 1024) {
      console.error("Error: Image size should not exceed 1MB.");
      failureAlert("Error: Image size should not exceed 1MB.")
      return;
    }

    const img = new Image();
    img.onload = async function () {
      const height = this.height;
      const width = this.width;

      const data = new FormData();
      data.append("profile-uploads", file);

      axiosApiInstance.post(BASE_URL + "/api/file/upload", data)
        .then(async (response) => {
          setProfileImg(response.data.data.location);
          setIsImgUpload(true);

        })
        .catch((err) => {
          console.error(`Fetch Error: ${err}`);
        });
    };

    const _URL = window.URL || window.webkitURL;
    img.src = _URL.createObjectURL(file);
  }

  const width = window.innerWidth


  function calculateProfileCompletion(profileCompletionDetailsArray) {
    if (profileCompletionDetailsArray && profileCompletionDetailsArray.length !== 0) {
      const requiredKeys = ["name", "profileImg", "phoneNumber"]; // Keys to check
      const totalKeys = requiredKeys.length;

      const presentKeys = new Set();

      profileCompletionDetailsArray.forEach((item) => {
        requiredKeys.forEach((key) => {
          if (item.hasOwnProperty(key)) {
            presentKeys.add(key);
          }
        });
      });

      const completionPercentage = (presentKeys.size / totalKeys) * 100;

      return Math.floor(completionPercentage);
    }
    else {
      return 0
    }

  }

  const calculateLoginBarRange = (data) => {
    if (data && data.length > 0) {
      if (data.length >= 5 && data.length < 10) {
        return 33.33
      }
      else if (data.length >= 10 && data.length < 15) {
        return 66.66
      }
      else if (data.length >= 15) {
        return 100
      }
    }
    else {
      return 0
    }
  }

  function formatNumberWithCommas(number) {
    return new Intl.NumberFormat("en-US").format(number);
  }

  const handleEditUserDetail = (e) => {
    e.preventDefault()
    setOpenEditUserModal(true)

  }

  const capitalizeFirstAlpabate = (name) => {
    if (typeof name !== 'string' || name.length === 0) {
      return '';
    }
    let spaceTrimName = name.replace(/\s/g, '');
    return spaceTrimName.charAt(0).toUpperCase() + spaceTrimName.slice(1).toLowerCase();
  }

  const openUploadModel = (e) => {
    e.preventDefault()
    setIsOpenUploadAvatarModel(true)
  }

  return (
    <div className='flex flex-col items-start w-full'>
      <Helmet>
        <title>My Detail</title>
        <meta name="description" content="My Detail" />
        <meta name="keywords" content="My Detail" />
      </Helmet>
      <ToastContainer position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      {
        isOpenUploadAvatarModel &&
        <UploadProfileAvatarModel toggle={isOpenUploadAvatarModel} setIsOpen={setIsOpenUploadAvatarModel} setfailureAlert={failureAlert} setsuccessAlert={successAlert} />
      }
    
      {/* {
        isImgUpload &&
        <ConfirmModal modalid={'profile-upload'} toggle={isImgUpload} setOpenConfirmModal={setIsImgUpload} confirmFunction={handleUploadImg} question={"Are you sure you want to upload this image as your profile picture?"} />
      } */}
      {
        openEditUserModal &&
        <EditUserDetailModal toggle={openEditUserModal} modalid={'user-edit-modal'} setOpenEditUserModel={setOpenEditUserModal} userInfoData={details} />
      }
      <div className="flex lg:flex-row flex-col-reverse justify-start lg:justify-between items-center w-full">
        {
          loaded !== false ? (
            <>
              <div className="flex md:flex-row lg:flex-row flex-col justify-between items-start md:items-center lg:items-center lg:mb-0 p-[15px] lg:p-[25px] rounded-[6px] w-full lg:w-[73%] h-auto lg:h-[260px]"
                style={{
                  background: 'transparent linear-gradient(103deg, #1FC0D6 0%, #4362A0 49%, #582D86 100%) 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 34px #00000029'
                }}
              >
                <div className="flex lg:flex-row flex-col items-start lg:items-center w-full md:w-auto lg:w-auto h-full">
                  <div className="flex flex-col justify-between items-center w-[105px] md:w-[105px] lg:w-[180px] h-full">
                    <div className="relative flex bg-white rounded-full w-[105px] md:w-[105px] lg:w-[180px] h-[105px] md:h-[105px] lg:h-[180px] cursor-pointer md">
                      {
                        profileImg && profileImg !== '' ? (
                          <img src={profileImg} alt="user-profile-image" className='rounded-full w-full h-full object-fill' />

                        ) : (
                          <div className="flex justify-center items-end rounded-full w-full h-full">
                            <img src={DefaultUser} alt="user-profile-image" className='rounded-full w-[95%] h-[90%] object-fill' />

                          </div>

                        )

                      }
                      <div className="right-[9px] md:right-[16px] lg:right-[25px] bottom-[-2px] md:bottom-[-3px] lg:bottom-[-3px] absolute flex justify-center items-center bg-white rounded-full w-[28px] md:w-[32px] lg:w-[40px] h-[28px] md:h-[32px] lg:h-[40px]"
                        onClick={openUploadModel}
                        style={{
                          boxShadow: '0px 4px 34px #00000029'
                        }}
                      >
                        {/* <input type="file" id='file-input' name='user-profile-upload' className='absolute hidden rounded-full w-full h-full' accept=".png, .jpeg, .jpg"
                          //  onChange={handleUserProfileImg}
                          onClick={openUploadModel}
                        /> */}
                        <FiUpload style={{ width: '50%', height: '50%', color: 'black' }} />
                      </div>

                    </div>
                    <span className='lg:flex hidden font-sans text-[#FFFFFF] text-[14px] sm:text-[14px] md:text-[14px] lg:text-[14px] underline whitespace-nowrap'>
                      Upload Profile Picture
                    </span>
                  </div>
                  <span className='flex lg:hidden mt-[10px] font-sans text-[#FFFFFF] text-[14px] sm:text-[14px] md:text-[14px] lg:text-[14px] underline whitespace-nowrap'>
                    Upload Profile Picture
                  </span>

                  <div className="flex flex-col justify-between items-start mt-[20px] lg:mt-0 mb-[19px] md:mb-0 lg:mb-0 lg:ml-[20px] md:border-none lg:border-none w-full lg:w-[calc(100%-200px)] h-auto lg:h-full">
                    <div className="flex flex-col justify-start items-start">
                      <h3 className='font-sans font-semibold text-[#FFFFFF] text-[18px] sm:text-[18px] md:text-[28px] lg:text-[28px] whitespace-nowrap'>
                        Name: {details.firstName ? capitalizeFirstAlpabate(details.firstName) + " " + capitalizeFirstAlpabate(details.lastName) : ''}
                      </h3>
                      <span className='font-sans text-[#FFFFFF] text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] whitespace-nowrap'>
                        Email ID: {details.email ? details.email : ''}
                      </span>
                      <span className='font-sans text-[#FFFFFF] text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] whitespace-nowrap'>
                        Mobile No: {details.phoneNumber ? details.phoneNumber : ''}
                      </span>
                    </div>

                    <div className="flex flex-col justify-start items-start mt-[15px] lg:mt-0 w-full">
                      <span className='font-sans text-[#FFFFFF] text-[16px] sm:text-[16px] md:text-[16px] lg:text-[16px] whitespace-nowrap'>
                        Profile Completion {userPoinstData && userPoinstData?.profileCompletionDetails?.length > 0 ? calculateProfileCompletion(userPoinstData?.profileCompletionDetails) : 0}%
                      </span>
                      <div className={`${userPoinstData && userPoinstData?.profileCompletionDetails?.length > 0 ? calculateProfileCompletion(userPoinstData?.profileCompletionDetails) < 100 ? 'border-[0.25px] border-white' : '' : 'border-[0.25px] border-white'} relative flex mt-[7px] rounded-[9px] w-[90%] md:w-full lg:w-full h-[19px] items-center`}>
                        <div className="relative flex rounded-[9px] h-[18px] profile-progress-bar-incompete"
                          style={{
                            width: `${userPoinstData && userPoinstData?.profileCompletionDetails?.length > 0 ? calculateProfileCompletion(userPoinstData?.profileCompletionDetails) : 0}%`
                          }}
                        >

                        </div>
                        <div className="top-[-60px] right-[-70px] absolute flex justify-center items-center w-[150px] h-[150px]">
                          <img src={crateImg} alt="profile-crate-image" className='w-full h-full object-fill' />
                        </div>

                      </div>

                      <div className="flex flex-row items-center mt-[10px] cursor-pointer"
                        onClick={handleEditUserDetail}
                      >
                        <FaRegEdit style={{ width: '16px', height: '16px', color: 'white' }} />
                        <span className='ml-[5px] font-sans text-[#FFFFFF] text-[16px] sm:text-[16px] md:text-[16px] lg:text-[16px] underline whitespace-nowrap'>
                          Edit Profile
                        </span>
                      </div>

                    </div>

                  </div>

                </div>

                <div className="relative flex flex-row md:flex-col lg:flex-col justify-start md:justify-center lg:justify-center items-center border-white border-t-[1px] md:border-none lg:border-none w-full md:w-[230px] lg:w-[240px] h-[130px] md:h-full lg:h-full">
                  <div className="relative flex justify-center items-center w-[120px] md:w-[230px] lg:w-[240px] h-[130px] md:h-full lg:h-full">
                    <Player
                      src={lightFile}
                      background="transparent"
                      speed={1}
                      // style={{ width: "", height: "100%" }}
                      className='w-[140px] md:w-[230px] lg:w-[240px] h-[130px] md:h-full lg:h-full'
                      loop
                      autoplay
                    />
                    <img src={profileStar} alt="profile-compete-starts" className='top-[28%] md:top-[32%] lg:top-[31%] left-[9%] md:left-[17%] lg:left-[19%] absolute w-[100px] md:w-[150px] lg:w-[150px] h-[50px] md:h-[75px] lg:h-[75px]' />

                  </div>

                  <span className='bottom-0 md:absolute lg:absolute flex ml-[15px] sm:ml-[20px] md:ml-0 lg:ml-0 md:w-[240px] lg:w-[240px] font-semibold md:font-normal lg:font-normal text-[18px] text-white md:text-[16px] lg:text-[16px]'>50 points on profile completion!</span>
                </div>
              </div>

              <div className="relative light-beam-container mb-[19px] lg:mb-0">

                {
                  width <= 1024 && width >= 640 ? (<>
                    <div className="light-beam-background">
                    </div>
                    <img src={tabConfetti} alt="confetti-img" className='w-full h-full object-fill' />

                  </>
                  ) :
                    <img src={confettiImg} alt="confetti-img" className='w-full h-full object-fill' />

                }
                <div className="absolute flex justify-center items-center bg-white rounded-full w-[105px] lg:w-[105px] h-[105px] lg:h-[105px]">

                  {
                    profileImg && profileImg !== '' ? (
                      <img src={profileImg} alt="user-profile-image" className='rounded-full w-full h-full object-fill' />

                    ) : (
                      <div className="flex justify-center items-end rounded-full w-full h-full">
                        <img src={DefaultUser} alt="user-profile-image" className='rounded-full w-[95%] h-[90%] object-fill' />

                      </div>

                    )

                  }
                </div>
                <div className="bottom-[16px] sm:bottom-[-3px] md:bottom-0 lg:bottom-0 absolute flex flex-col justify-center items-center pb-2">
                  <span className='text-[14px] text-white sm:text-[18px] md:text-[18px] lg:text-[18px]'>{details.firstName ? details.firstName + " " + details.lastName : ''}</span>
                  <span className='font-semibold text-[14px] text-white sm:text-[20px] md:text-[20px] lg:text-[20px]'>{userPoinstData && userPoinstData?.totalPoints ? formatNumberWithCommas(userPoinstData?.totalPoints) + " Points" : 0}</span>
                </div>
              </div>
            </>
          ) : (<>
            <div className="flex md:flex-row lg:flex-row flex-col justify-between items-start md:items-center lg:items-center bg-slate-100 lg:mb-0 p-[15px] lg:p-[25px] rounded-[6px] w-full lg:w-[73%] h-auto lg:h-[260px] skeleton-animation"></div>
            <div className="relative bg-slate-100 light-beam-container-loadiing mb-[19px] lg:mb-0 skeleton-animation"></div>
          </>
          )
        }




      </div>

      <div className="flex flex-row flex-nowrap justify-start items-center gap-x-[19px] mt-[16px] w-full h-[114px] md:h-[95px] lg:h-[114px] overflow-x-auto no-scrollbar">
        {
          loaded !== false ? (<>
            <div className="flex flex-col justify-center items-start lg:mb-0 p-[20px] lg:p-[25px] rounded-[6px] md:!w-1/3 lg:!w-1/3 min-w-[170px] max-w-[250px] md:max-w-none lg:max-w-none h-[115px] md:h-full lg:h-full"
              style={{
                background: 'transparent linear-gradient(125deg, #FCE38A 0%, #F38181 100%) 0% 0% no-repeat padding-box'
              }}
            >
              <span className='font-sans font-semibold text-[21px] text-white lg:text-[24px]'>5 Points</span>
              <span className='font-sans text-[18px] text-white lg:text-[20px]'>Name Added</span>
            </div>

            <div className="flex flex-col justify-center items-start lg:mb-0 p-[20px] lg:p-[25px] rounded-[6px] md:!w-1/3 lg:!w-1/3 min-w-[230px] max-w-[250px] md:max-w-none lg:max-w-none h-[115px] md:h-full lg:h-full"
              style={{
                background: 'transparent linear-gradient(114deg, #F54EA2 0%, #FF7676 100%) 0% 0% no-repeat padding-box'
              }}
            >
              <span className='font-sans font-semibold text-[21px] text-white lg:text-[24px]'>15 Points</span>
              <span className='font-sans text-[18px] text-white lg:text-[20px]'>Profile Photo Uploaded</span>
            </div>



            <div className="flex flex-col justify-center items-start p-[20px] lg:p-[25px] rounded-[6px] md:!w-1/3 lg:!w-1/3 min-w-[230px] md:max-w-none lg:max-w-none h-[115px] md:h-full lg:h-full"
              style={{
                background: 'transparent linear-gradient(115deg, #17EAD9 0%, #6078EA 100%) 0% 0% no-repeat padding-box'
              }}
            >
              <span className='font-sans font-semibold text-[21px] text-white lg:text-[24px]'>30 Points</span>
              <span className='font-sans text-[18px] text-white lg:text-[20px]'>Phone Number Added</span>
            </div>
          </>)
            : (
              <>
                <div className="flex flex-col justify-center items-start bg-slate-100 lg:mb-0 p-[20px] lg:p-[25px] rounded-[6px] md:!w-1/3 lg:!w-1/3 min-w-[170px] max-w-[250px] md:max-w-none lg:max-w-none h-[115px] md:h-full lg:h-full skeleton-animation"></div>
                <div className="flex flex-col justify-center items-start bg-slate-100 lg:mb-0 p-[20px] lg:p-[25px] rounded-[6px] md:!w-1/3 lg:!w-1/3 min-w-[230px] max-w-[250px] md:max-w-none lg:max-w-none h-[115px] md:h-full lg:h-full skeleton-animation"></div>
                <div className="flex flex-col justify-center items-start bg-slate-100 p-[20px] lg:p-[25px] rounded-[6px] md:!w-1/3 lg:!w-1/3 min-w-[230px] md:max-w-none lg:max-w-none h-[115px] md:h-full lg:h-full skeleton-animation"></div>

              </>
            )
        }

      </div>
      {
        loaded !== false ? (
          <>
            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-50px)] md:w-[calc(100%-50px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'> Participation & <span className='font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Performance:</span></span>
                  <span className='font-sans text-[#b9b3b3] text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px]'>Participation in any game: 5 Points</span>
                  <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col mt-[19px] sm:mt-0 md:mt-0 lg:mt-0">
                    <span className='font-sans text-[16px] sm:text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>1st Winner: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>20 Points</span>  </span>
                    <span className="sm:flex md:flex lg:flex hidden px-2 text-[#b9b3b3] leading-[16px] lg:leading-[19px]">|</span>
                    <span className='mt-[10px] sm:mt-0 md:mt-0 lg:mt-0 font-sans text-[16px] sm:text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'> 2nd Winner: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>15 Points</span> </span>
                    <span className="sm:flex md:flex lg:flex hidden px-2 text-[#b9b3b3] leading-[16px] lg:leading-[19px]">|</span>
                    <span className='mt-[10px] sm:mt-0 md:mt-0 lg:mt-0 font-sans text-[16px] sm:text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'> 3rd Winner: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>10 Points</span>  </span>

                  </div>

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ? 100 : 0}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                                <></>

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>
                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[50px] lg:w-[70px] h-[30px] lg:h-[50px]">
                {
                  userPoinstData && userPoinstData?.userSessionPoints && userPoinstData?.userSessionPoints?.length > 0 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }
              </div>

            </div>

            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-50px)] md:w-[calc(100%-50px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'> Engagement-Based  <span className='font-sans font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Rewards:</span></span>
                  <span className='font-sans text-[16px] lg:text-[18px] leading-[35px] sm:leading-[10px] md:leading-[10px] lg:leading-[19px]'>Most Active User/Week: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>10 Points</span> </span>

                  {/* <div className="flex border-[#707070] border-[0.25px] mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] rounded-[9px] w-[calc(100%-70px)] lg:w-[420px] h-[18px]">
              <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                style={{
                  width: `${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? 100 : 0}%`
                }}
              ></div>
            </div> */}

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ? 100 : 0}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                                <></>

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>

                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[50px] lg:w-[70px] h-[30px] lg:h-[50px]">
                {
                  userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates && userPoinstData?.userLoginEngagementDetails?.weeklyLoginDates.length >= 7 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }        </div>

            </div>

            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 5 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 5 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'> Daily Login  <span className='font-sans font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Streak (Mon-Fri):</span></span>
                  <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col mt-[15px] sm:mt-0 md:mt-0 lg:mt-0">
                    <span className='font-sans text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>5 Days: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>15 Points</span>  </span>
                    <span className="sm:flex md:flex lg:flex hidden px-2 text-[#b9b3b3] leading-[16px] lg:leading-[19px]">|</span>
                    <span className='mt-[10px] sm:mt-0 md:mt-0 lg:mt-0 font-sans text-[16px] lg:text-[18px] leading-[16px]'> 10 Days: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>40 Points</span> </span>
                    <span className="sm:flex md:flex lg:flex hidden px-2 text-[#b9b3b3] leading-[16px] lg:leading-[19px]">|</span>
                    <span className='mt-[10px] sm:mt-0 md:mt-0 lg:mt-0 font-sans text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'> 15 Days: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'>75 Points</span>  </span>
                    <span className="sm:flex md:flex lg:flex hidden px-2 text-[#b9b3b3] leading-[16px] lg:leading-[19px]">|</span>
                    <span className='mt-[10px] sm:mt-0 md:mt-0 lg:mt-0 font-sans text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'> Each Additional 5 Days: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[16px] lg:leading-[19px]'> 50 Points</span>  </span>


                  </div>
                  {/* <div className={`flex ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 15 ? '' : 'border-[#707070] border-[0.25px]'} mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] rounded-[9px] w-[calc(100%-70px)] lg:w-[420px] h-[18px]`}>
              <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 15 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                style={{
                  width: `${calculateLoginBarRange(userPoinstData?.userLoginEngagementDetails?.login_dates)}%`
                }}
              ></div>
            </div> */}

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 15 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 15 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${calculateLoginBarRange(userPoinstData?.userLoginEngagementDetails?.login_dates)}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 5 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                                <></>

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>

                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[70px] h-[50px]">
                {
                  userPoinstData && userPoinstData?.userLoginEngagementDetails && userPoinstData?.userLoginEngagementDetails?.login_dates && userPoinstData?.userLoginEngagementDetails?.login_dates.length >= 5 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }        </div>

            </div>

            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'> Game Feedback  <span className='font-sans font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Submission:</span></span>
                  <span className='font-sans text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>Feedback On Each Game: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>10 Points</span> </span>

                  {/* <div className={`flex ${userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] rounded-[9px] w-[calc(100%-70px)] lg:w-[420px] h-[18px]`}>
              <div className={`rounded-[9px] h-full ${10 >= 100 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                style={{
                  width: `${userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? 100 : 0}%`
                }}
              ></div>
            </div> */}

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ? 100 : 0}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                                <></>

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>

                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[70px] h-[50px]">
                {
                  userPoinstData && userPoinstData?.GameFeedbackDetails && userPoinstData?.GameFeedbackDetails?.length > 0 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }        </div>

            </div>

            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Experience  <span className='font-sans font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Streak:</span></span>
                  <span className='font-sans text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>Participation In Each Category: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>100 Points</span> </span>

                  {/* <div className={`flex ${userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] rounded-[9px] w-[calc(100%-70px)] lg:w-[420px] h-[18px]`}>
              <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                style={{
                  width: `${userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? 100 : 0}%`
                }}
              ></div>
            </div> */}

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ? 100 : 0}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                                <></>

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>

                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[70px] h-[50px]">
                {
                  userPoinstData && userPoinstData?.categoryPoints && userPoinstData?.categoryPoints?.length > 0 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }        </div>

            </div>


            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Teams  <span className='font-sans font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Created:</span></span>
                  <span className='font-sans text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>Member Of Team: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>50 Points</span> </span>

                  {/* <div className={`flex ${userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] rounded-[9px] w-[calc(100%-70px)] lg:w-[420px] h-[18px]`}>
              <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                style={{
                  width: `${userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? 100 : 0}%`
                }}
              ></div>
            </div> */}

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ? 100 : 0}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                                <></>

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>

                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[70px] h-[50px]">
                {
                  userPoinstData && userPoinstData?.userTeamsPoints && userPoinstData?.userTeamsPoints?.length > 0 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }
              </div>

            </div>


            <div className="flex flex-row justify-between items-center bg-white mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px]"
              style={{
                boxShadow: '0px 4px 34px #00000029'
              }}
            >
              <div className="flex sm:flex-row md:flex-row lg:flex-row flex-col items-center w-full sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)] lg:w-[calc(100%-70px)] h-full">
                <div className={`rounded-[6px] ${width > 400 && width <= 640 ? 'h-[200px]' : 'h-[125px]'} w-full sm:w-[130px] md:w-[130px] lg:w-[168px]  sm:h-full md:h-full lg:h-full`}>
                  {
                    width > 640 ?
                      userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? (
                        <img src={completeStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={inCompleteStars} alt="task-completed" className='w-full h-full object-fill' />

                      )

                      : userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? (
                        <img src={mobileStars} alt="task-completed" className='w-full h-full object-fill' />

                      ) : (
                        <img src={mobileInCompleteStar} alt="task-completed" className='w-full h-full object-fill' />

                      )
                  }
                </div>
                <div className="flex flex-col justify-between items-start mt-[29px] sm:mt-0 md:mt-0 lg:mt-0 mb-[10px] sm:mb-[5px] md:mb-[5px] lg:mb-[5px] ml-0 sm:ml-[15px] md:ml-[15px] lg:ml-[15px] w-full sm:w-[calc(100%-130px)] md:w-[calc(100%-130px)] lg:w-[calc(100%-168px)] h-full">
                  <span className='font-sans font-semibold text-[21px] sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Captain  <span className='font-sans font-semibold text-[21px] text-themeColor sm:text-[21px] md:text-[22px] lg:text-[30px] leading-[18px] sm:leading-[20px] md:leading-[22px] lg:leading-[30px]'>Appointed:</span></span>
                  <span className='font-sans text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>Captain Appointed Of Each Team: <span className='font-sans font-semibold text-[16px] lg:text-[18px] leading-[35px] sm:leading-[16px] md:leading-[16px] lg:leading-[19px]'>30 Points</span> </span>

                  {/* <div className={`flex ${userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] rounded-[9px] w-[calc(100%-70px)] lg:w-[420px] h-[18px]`}>
              <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                style={{
                  width: `${userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? 100 : 0}%`
                }}
              ></div>
            </div> */}

                  <div className="flex flex-row justify-between items-center mt-[25px] sm:mt-[5px] md:mt-[5px] lg:mt-[5px] mb-[5px] w-full">
                    <div className={`flex ${userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? '' : 'border-[#707070] border-[0.25px]'}  rounded-[9px] w-[calc(100%-50px)] sm:w-[calc(100%-70px)] md:w-[calc(100%-70px)]  lg:w-[420px] h-[18px]`}>
                      <div className={`rounded-[9px] h-full ${userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? 'progress-bar-full' : 'progress-bar-incompete'}`}
                        style={{
                          width: `${userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ? 100 : 0}%`
                        }}
                      ></div>
                    </div>
                    {
                      width < 640 ? (
                        <div className="flex justify-center items-center sm:hidden md:hidden lg:hidden w-[40px] h-[28px]">
                          {
                            userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ?
                              (
                                <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                              ) : (
                                <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />

                              )
                          }
                        </div>

                      ) : null
                    }
                  </div>

                </div>

              </div>

              <div className="sm:flex md:flex lg:flex justify-center items-center hidden mr-[5px] lg:mr-[50px] rounded-[6px] w-[70px] h-[50px]">
                {
                  userPoinstData && userPoinstData?.userTeamscaptainPoints && userPoinstData?.userTeamscaptainPoints?.length > 0 ?
                    (
                      <img src={completeTickMark} alt="task-completed" className='w-full h-full object-fill' />

                    ) : (
                      // <img src={InCompleteTick} alt="task-completed" className='w-full h-full object-fill' />
                      <></>

                    )
                }
              </div>

            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>
            <div className="flex flex-row justify-between items-center bg-slate-100 mt-[16px] p-[19px] rounded-[6px] w-full h-auto sm:h-[130px] md:h-[130px] lg:h-[161px] skeleton-animation"></div>

          </>
        )
      }


    </div>
  );
};

export default Account;
